import React, { useEffect } from 'react';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';

import {
  Box,
  TabList,
  Tab,
  Tabs,
  TabIndicator,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';

import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import JSEditor from '../editors/JSEditor';
import CssEditor from '../editors/CssEditor';
import HtmlEditor from '../editors/HtmlEditor';
import CardFooterInfo from './CardFooterInfo';
import CardHeaderInfo from './CardHeaderInfo';

const CodeBlockView = () => {
  const { getRecentPublic, viewAllCodeBlocks } = userCodeBlockStore(
    (state: any) => state
  );

  useEffect(() => {
    try {
      const getCodeblocks = async () => {
        await getRecentPublic();
      };
      getCodeblocks();
    } catch (err) {
      console.log(err);
    }
  }, []);

  const defaultTextColor = SelectedDefaultTextColor();

  return (
    <Box my={8}>
      {viewAllCodeBlocks.map((item, index) => {
        return (
          <Box
            color={defaultTextColor?.backgroundText}
            rounded="md"
            border={'1px solid rgba(255, 255, 255, 0.125)'}
            borderRadius={10}
            style={{
              backdropFilter: 'blur( 4.5px ) saturate(180%)',
            }}
            px={6}
            bg={'rgba(44, 63, 82, 0.75)'}
            boxShadow="xl"
            mb={6}
            key={index}
          >
            <CardHeaderInfo userInfo={item} />
            <Box mb={4}> {item?.note}</Box>
            <Box
              h="500px"
              w="100%"
              color={defaultTextColor?.backgroundText}
              border={'1px solid rgba(255, 255, 255, 0.125)'}
            >
              {/* <Text fontSize="4xl"> Edit</Text> */}

              <Box
                w="100%"
                style={{
                  backdropFilter: 'blur( 4.5px ) saturate(180%)',
                }}
                bg={'rgba(44, 63, 82, 0.75)'}
              >
                <Tabs
                  h="498px"
                  //   ml={2}
                  w="100%"
                  position="relative"
                  variant="unstyled"
                >
                  <TabList>
                    <Tab>J/S</Tab>
                    <Tab>CSS</Tab>
                    <Tab>HTML</Tab>
                  </TabList>
                  <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg={defaultTextColor?.foregroundText}
                    borderRadius="1px"
                  />

                  <TabPanels w="100%" h="400px">
                    <TabPanel>
                      <Box>
                        <JSEditor
                          value={item?.javascript}
                          setJsValue={() => {}}
                        />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box>
                        <CssEditor value={item?.css} setCssValue={() => {}} />
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Box>
                        <HtmlEditor
                          value={item?.html}
                          setHtmlValue={() => {}}
                        />
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </Box>
            <CardFooterInfo cardInfo={item} />
          </Box>
        );
      })}
    </Box>
  );
};

export default CodeBlockView;
