import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { providerDBVerification } from '../config/AppWrite/Apis/userApi';
import { useUserStore } from '../stores/useUserStore';

const ProviderVerification = () => {
  const { getUsers, userError } = useUserStore((state: any) => state);
  const navigate = useNavigate();
  useEffect(() => {
    const userAndTheme = async () => {
      try {
        await providerDBVerification();
        // await getUsers();
        navigate('/');
      } catch (err: any) {
        console.log('err', err);
      }
    };
    userAndTheme();
  }, []);

  return <div>ProviderVerification</div>;
};

export default ProviderVerification;
