import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { } from '@redux-devtools/extension';
import { createBlock, deleteBlock, deleteSavedCodeBlock, getBlockByCategory, getCreatorBlock, getRecentPublicCodeBlocks, getSingleBlock, getUserBlockByCategory, getUserBlockByCategoryFolders, getUserBlockByNoFolders, likeCodeBlock, updateSingleCodeBlock } from '../config/AppWrite/Apis/codeBlockApi';
import { IcodeBlock } from '../types/types';

interface codeblockState {
    codeBlockData?: any;
    codeBlockDataList?: any;
    codeBlockCreator?: string;
    codeBlockCategory?: '';
    codeBlockCategories?: [];
    isCodeBlockLoading?: boolean;
    isSingleCodeBlockLoading?: boolean;
    codeBlockError?: any;
    folderView?: string;
    editCodeBlock?: boolean;
    viewAllCodeBlocks?: any;
    favoriteCodeBlocks?: [any];
    savedCodeBlocks?: [any];
    getCreatorCodeBlock: (codeBlockDataList: any) => void;
    getCodeBlock: any
    getUserCodeBlockFolders: (creator: string) => void
    setCodeBlockData: ([any]) => void
    setCodeBlockCreator: ([any]) => void
    setCodeBlockCategory: ([any]) => void
    setCodeBlockCategories: ([any]) => void
    setCodeBlockDataList: (codeBlockDataList) => void
    setEditCodeBlock: (editCodeBlock) => void
}

export const userCodeBlockStore = create<codeblockState>()(
    devtools(
        persist(
            set => ({
                codeBlockData: [],
                codeBlockDataList: [],
                codeBlockCreator: '',
                codeBlockCategory: '',
                codeBlockCategories: [],
                isCodeblockLoading: false,
                isSingleCodeblockLoading: false,
                codeBlockError: null,




                setCodeBlockData: (codeBlockData: any) => set(codeBlockData),
                setCodeBlockCreator: (codeBlockCreator: any) => set(codeBlockCreator),
                setCodeBlockCategory: (codeBlockCategory: any) =>
                    set(codeBlockCategory),
                setCodeBlockCategories: (codeBlockCategories: any) =>
                    set(codeBlockCategories),
                setCodeBlockDataList: (codeBlockDataList: any) =>
                    set({ codeBlockDataList: codeBlockDataList }),
                createCodeBlock: async (codeBlockData: IcodeBlock) => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await createBlock(codeBlockData);

                        set({ isCodeBlockLoading: false, codeBlockData: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },


                getCodeBlock: async (id: string, category: string, creator: string) => {

                    try {
                        set({ isSingleCodeBlockLoading: true });
                        const response = await getSingleBlock({ id, category, creator });

                        set({ isSingleCodeBlockLoading: false, codeBlockData: response?.documents[0] });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                getCreatorCodeBlock: async (creator: string) => {

                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getCreatorBlock(creator);

                        set({ isCodeBlockLoading: false, codeBlockDataList: response?.documents });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                getCodeBlockByCategies: async (category1: string, category2: string) => {


                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getBlockByCategory({ category1, category2 });

                        set({ isCodeBlockLoading: false, codeBlockDataList: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                getUserCodeBlockByCategies: async (id: string, category: string) => {


                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getUserBlockByCategory(id, category);
                        set({ isCodeBlockLoading: false, codeBlockDataList: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },

                getUserCodeBlockFolders: async (creator: string) => {

                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getUserBlockByCategoryFolders(creator);

                        set({ isCodeBlockLoading: false, codeBlockDataList: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                getUserCodeBlockNoFolder: async (creator: string) => {

                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getUserBlockByNoFolders(creator);

                        set({ isCodeBlockLoading: false, codeBlockDataList: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                deleteCodeBlock: async (id: string) => {

                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await deleteBlock(id);

                        set({ isCodeBlockLoading: false, codeBlockData: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                folderView: 'folder',
                editCodeBlock: false,
                setEditCodeBlock: (edit: any) => set({ editCodeBlock: edit }),
                updateCodeBlock: async (codeBlockData: IcodeBlock) => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await updateSingleCodeBlock(codeBlockData);

                        set({ isCodeBlockLoading: false, codeBlockData: response });
                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                viewAllCodeBlocks: [],
                getRecentPublic: async () => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await getRecentPublicCodeBlocks()

                        set({ isCodeBlockLoading: false, viewAllCodeBlocks: response?.documents });
                    } catch (err: any) {
                        set({ codeBlockError: err });

                    }
                },
                favoriteCode: async (id, favoritesArray) => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await likeCodeBlock(id, favoritesArray)

                        set({ isCodeBlockLoading: false });
                        return response

                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                saveCode: async (id, userId) => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await likeCodeBlock(id, userId)

                        set({ isCodeBlockLoading: false });
                        return response

                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                },
                deleteSavedCode: async (id) => {
                    try {
                        set({ isCodeBlockLoading: true });
                        const response = await deleteSavedCodeBlock(id)

                        set({ isCodeBlockLoading: false });
                        return response

                    } catch (err: any) {
                        set({ codeBlockError: err });
                        throw new Error(err);
                    }
                }


            }),
            {
                name: 'codeBlock-storage',
            }
        )
    )
);
