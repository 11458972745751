import React, { useEffect } from 'react';
import {
  Flex,
  Button,
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Divider,
} from '@chakra-ui/react';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import { userThemeStore } from '../../stores/useThemesStore';
import { useUserStore } from '../../stores/useUserStore';

const EditTheme = () => {
  useEffect(() => {
    getThemes();
  }, []);
  const {
    getThemes,
    allThemes,
    theme,

    setTheme,

    isLoading,
  } = userThemeStore((state: any) => state);
  const { updateUserInfo, userData } = useUserStore((state: any) => state);
  const textColor = SelectedDefaultTextColor();
  const saveUpdatedTheme = async () => {
    await updateUserInfo({
      $id: userData.$id,
      data: {
        theme: theme.name,
      },
    });

    await getThemes();
  };

  return (
    <Box>
      <Card
        minH="400px"
        backgroundColor="transparent"
        boxShadow="xl"
        minW="md"
        w="100%"
        color={textColor?.backgroundText}
        border={!theme?.gradient ? '1px solid black' : ''}
      >
        <CardHeader>
          <Heading>Themes</Heading>
        </CardHeader>
        <CardBody>
          {allThemes?.map((selectTheme: any, index: number) => {
            return (
              <Flex
                key={index}
                onClick={() => setTheme(selectTheme)}
                direction={'column'}
                alignItems={'center'}
                cursor={'grab'}
              >
                <Heading mb={2} size="md" textTransform="uppercase">
                  {selectTheme?.name}
                </Heading>
                <Box
                  mb={4}
                  w="200px"
                  h="10px"
                  backgroundColor={
                    !selectTheme?.gradient ? selectTheme?.backgroundSolid : ''
                  }
                  border={!selectTheme?.gradient ? '1px solid black' : ''}
                  bgGradient={
                    selectTheme?.gradient
                      ? `linear(to-r, ${selectTheme?.backgroundGradient[0]}, ${selectTheme?.backgroundGradient[1]}, ${selectTheme?.backgroundGradient[2]})`
                      : ''
                  }
                ></Box>
                <Divider mb={4} />
              </Flex>
            );
          })}
          <Flex w="100%" justifyContent={'flex-end'}>
            {isLoading ? (
              <Button
                bg={textColor?.backgroundText}
                color={textColor?.foregroundText}
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                isLoading
                loadingText="Saving"
              ></Button>
            ) : (
              <Button
                bg={textColor?.backgroundText}
                color={textColor?.foregroundText}
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                onClick={() => saveUpdatedTheme()}
              >
                Save
              </Button>
            )}
          </Flex>
        </CardBody>
      </Card>
    </Box>
  );
};

export default EditTheme;
