'use client';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  TabIndicator,
  Skeleton,
} from '@chakra-ui/react';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';
import { useUserStore } from '../../stores/useUserStore';
import {
  SelectedBackgroundColor,
  SelectedDefaultTextColor,
} from '../../utils/colorSelection';
import JSEditor from '../../component/editors/JSEditor';
import HtmlEditor from '../../component/editors/HtmlEditor';
import CssEditor from '../../component/editors/CssEditor';

const ViewCodeBlock = ({ folderView, setFolderView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    codeBlockData,
    isSingleCodeBlockLoading,
    deleteCodeBlock,
    setEditCodeBlock,
    getUserCodeBlockByCategies,
  } = userCodeBlockStore((state: any) => state);
  const { userData } = useUserStore((state: any) => state);

  const [jsValue, setJsValue] = useState();
  const [htmlValue, setHtmlValue] = useState();
  const [cssValue, setCssValue] = useState();
  const textColor = SelectedDefaultTextColor();
  useEffect(() => {
    if (codeBlockData) {
      setJsValue(codeBlockData?.javascript);
      setHtmlValue(codeBlockData?.html);
      setCssValue(codeBlockData?.css);
    }
  }, [codeBlockData]);

  const editCode = async () => {
    localStorage.setItem(
      'DevErNote-current-notetitle',
      JSON.stringify(codeBlockData?.title || '')
    );
    localStorage.setItem(
      'DevErNote-current-note',
      JSON.stringify(codeBlockData?.note || '')
    );
    localStorage.setItem(
      'DevErNote-current-notejavascript',
      JSON.stringify(codeBlockData?.javascript || '')
    );
    localStorage.setItem(
      'DevErNote-current-notecss',
      JSON.stringify(codeBlockData?.css || '')
    );

    localStorage.setItem(
      'DevErNote-current-notehtml',
      JSON.stringify(codeBlockData?.html || '')
    );

    // await getUserCodeBlockByCategies(userData?.email, codeBlockData?.category);

    setEditCodeBlock(true);
    navigate('/playground');
  };

  const editSection = () => {
    return (
      <Box>
        <Box mt={2}>
          <Button
            color="black"
            w="150px"
            mt={2}
            ml={4}
            rounded={'full'}
            size={'md'}
            fontWeight={'normal'}
            onClick={() => editCode()}
          >
            Edit
          </Button>
          <Button
            color="black"
            w="150px"
            mt={2}
            ml={4}
            rounded={'full'}
            size={'md'}
            fontWeight={'normal'}
            onClick={() => {
              deleteCodeBlock(codeBlockData?.$id);
              setFolderView('folder');
            }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      h="500px"
      w="100%"
      color={textColor?.backgroundText}
      border={'1px solid rgba(255, 255, 255, 0.125)'}
    >
      {/* <Text fontSize="4xl"> Edit</Text> */}

      <Box
        w="100%"
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        bg={'rgba(44, 63, 82, 0.75)'}
      >
        <Tabs h="498px" ml={2} w="100%" position="relative" variant="unstyled">
          <TabList>
            <Tab>J/S</Tab>
            <Tab>CSS</Tab>
            <Tab>HTML</Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg={textColor?.foregroundText}
            borderRadius="1px"
          />

          <TabPanels w="100%">
            <TabPanel>
              <Box>
                <Skeleton isLoaded={!isSingleCodeBlockLoading}>
                  <JSEditor value={jsValue} setJsValue={setJsValue} />
                </Skeleton>
                {editSection()}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <CssEditor value={cssValue} setCssValue={setCssValue} />
                {editSection()}
              </Box>
            </TabPanel>
            <TabPanel>
              <Box>
                <HtmlEditor value={htmlValue} setHtmlValue={setHtmlValue} />
                {editSection()}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default ViewCodeBlock;
