'use client';
import { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  Stack,
  Text,
  MenuDivider,
} from '@chakra-ui/react';

import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
// import { AiOutlineMenuFold } from 'react-icons/ai';

// import { useCodeBlockStore } from '@/stores/useCodeBlockStore';
import { useUserStore } from '../../stores/useUserStore';
import { userThemeStore } from '../../stores/useThemesStore';

import './headerStyles.css';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import { useNavigate, Link } from 'react-router-dom';
const Links = [
  {
    name: 'Home',
    path: '/home',
  },
  {
    name: 'PlayGround',
    path: '/playground',
  },
  {
    name: 'Social Hub',
    path: '/socialhub',
  },
  {
    name: 'Utilities',
    path: '/utils',
  },
];

const NavLink = ({ children, path }: { children: ReactNode; path: string }) => (
  <Box
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: `${SelectedDefaultTextColor().foregroundText} underline`,
      bg: SelectedDefaultTextColor().foregroundText,
    }}
  >
    <Link to={path}>{children}</Link>
  </Box>
);

export default function WithAction() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { userData, signOutUser, getUsers } = useUserStore(
    (state: any) => state
  );
  const { themeName } = userThemeStore((state: any) => state);
  const [userName, setUserName] = useState('');

  const textColor = SelectedDefaultTextColor();
  const navigate = useNavigate();

  const shortString = (
    str: string | undefined | null
  ): string | undefined | null => {
    return str?.slice(0, 11);
  };

  useEffect(() => {
    const userAndTheme = async () => {
      try {
        await getUsers();
      } catch (err: any) {
        console.log('err', err);
      }
    };
    userAndTheme();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeName]);

  useEffect(() => {
    setUserName(userData?.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.name]);

  return (
    <Box
      bg={textColor}
      css={{ backgroundColor: '#2c3f52' }}
      // bgGradient={SelectedBackgroundColor()}
      borderBottom={'1px solid rgba(255, 255, 255, 0.125)'}
      className="mobileNav"
    >
      <Box color={textColor?.backgroundText} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'flex-end'}>
            <Box
              color={textColor?.backgroundText}
              fontSize="2rem"
              fontWeight="bold"
            >
              <Link to="/">DevErNote</Link>
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
              fontSize="1.3rem"
            >
              {Links.map(({ name, path }) => (
                <NavLink key={path} path={path}>
                  <Text fontFamily={'cursive'}>{name}</Text>
                </NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Text
            //  color={data?.theme?.backGroundText}
            ></Text>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
                colorScheme="white"
              >
                <Flex
                  flexDirection="row"
                  alignItems="flex-end"
                  fontSize="1.3rem"
                >
                  <Text
                    fontSize={{
                      base: 'sm',
                      sm: 'sm',
                      md: 'lg',
                      lg: '1.3rem',
                    }}
                    pr={2}
                  >
                    {shortString(userName) ? shortString(userName) : 'Login'}
                  </Text>
                  <Box
                    //    color={data?.theme?.backGroundText}
                    fontSize="4xl"
                  >
                    {/* <AiOutlineMenuFold /> */}
                  </Box>
                </Flex>
              </MenuButton>

              <MenuList color="black">
                {userData?.name ? (
                  <MenuItem
                    color="black"
                    as="button"
                    onClick={() => {
                      signOutUser();
                      navigate('/');
                    }}
                  >
                    Signout
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem
                      color="black"
                      as="button"
                      onClick={() => navigate('/signin')}
                    >
                      Login
                    </MenuItem>
                    <MenuItem
                      color="black"
                      as="button"
                      onClick={() => navigate('/register')}
                    >
                      Register
                    </MenuItem>
                  </>
                )}

                {userData?.name && (
                  <>
                    <MenuDivider />
                    <MenuItem>
                      <Link to="/profile">Profile</Link>
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map(({ name, path }) => (
                <Box
                  key={name}
                  //   onClick={() => {
                  //     name === 'Home' && getCodeBlockByCategory(data?.email);
                  //   }}
                >
                  <NavLink key={path} path={path}>
                    {name}
                  </NavLink>
                </Box>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
