import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../stores/useUserStore';
import { Box, Container, Input, Text, Select, Heading } from '@chakra-ui/react';
import FileUpload from '../component/FileUpload/FileUpload';
import { SelectedDefaultTextColor } from '../utils/colorSelection';

interface LinkData {
  name: string;
  url: string;
  type: 'generator';
}

const UtilsLinkUploads = () => {
  const { signUpAndSignInGoogleProvider, signUpUser, getUsers, userData } =
    useUserStore((state: any) => state);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const [backgroundColor, setBackgroundColor] = useState('#ffffff');
  const [blur, setBlur] = useState(10);
  const [opacity, setOpacity] = useState(0.7);
  const textColor = SelectedDefaultTextColor();
  const glassStyle = {
    backgroundColor: `${backgroundColor}${Math.round(opacity * 255).toString(
      16
    )}`,
    backdropFilter: `blur(${blur}px)`,
    borderRadius: '10px',
    padding: '20px',
    width: '300px',
    margin: '20px',
  };

  return (
    <Box color={textColor?.backgroundText}>
      <Container mt={20}>
        <Box>
          <Heading mb={4}>UtilsLinkUploads</Heading>
          <Text mb={4}>Name</Text>
          <Input onChange={e => setName(e.target.value)} mb={6} />
          <Text mb={4}>URL</Text>
          <Input onChange={e => setUrl(e.target.value)} mb={6} />
          <Text mb={4}>Type</Text>
          <Select
            placeholder="Select option"
            value={selectedOption}
            onChange={handleSelectChange}
            mb={8}
          >
            <option
              style={{ color: textColor?.foregroundText }}
              value="generator"
            >
              <Text>Generator</Text>
            </option>
            <option style={{ color: textColor?.foregroundText }} value="tools">
              <Text>Tools</Text>
            </option>
            <option style={{ color: textColor?.foregroundText }} value="other">
              <Text color={'black'}>Other</Text>
            </option>
          </Select>
        </Box>
        <FileUpload
          uploadPage={'utils'}
          name={name}
          url={url}
          type={selectedOption}
        />
        <div>
          <div style={glassStyle}>
            <h2>Your Glassmorphism Element</h2>
            <p>Adjust the settings below:</p>
          </div>

          <div>
            <label>Background Color:</label>
            <input
              type="color"
              value={backgroundColor}
              onChange={e => setBackgroundColor(e.target.value)}
            />

            <label>Blur:</label>
            <input
              type="range"
              min="0"
              max="30"
              value={blur}
              onChange={e => setBlur(parseInt(e.target.value, 10))}
            />

            <label>Opacity:</label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={opacity}
              onChange={e => setOpacity(parseFloat(e.target.value))}
            />
          </div>
        </div>
      </Container>
    </Box>
  );
};

export default UtilsLinkUploads;
