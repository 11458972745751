import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  Container,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { useUserStore } from '../../stores/useUserStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import { InfoIcon } from '@chakra-ui/icons';
import CardBox from '../StyleCompnents/CardBox';

const ForgotPasswordSendEmailComponent = () => {
  const { passwordRecovery } = useUserStore((state: any) => state);
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  const sendEmailSubmit = async () => {
    try {
      const send = await passwordRecovery(email);

      if (send) {
        setSentEmail(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const textColor = SelectedDefaultTextColor();

  const emailSent = () => {
    return (
      <Stack
        align={'center'}
        pt={{ base: 10, md: 40 }}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
      >
        <CardBox>
          <Box mt={6}>
            <Flex mb={6} flexDirection={'column'} alignItems={'center'}>
              <InfoIcon boxSize={16} mb={4} color={textColor?.backgroundText} />

              <Heading mb={4} color={textColor?.backgroundText}>
                Time To Check Your Email!
              </Heading>

              <Text mb={2} fontSize={'xl'} color={textColor?.backgroundText}>
                A password reset link has been sent to
              </Text>
              <Text color={textColor?.backgroundText}>{email}</Text>
              <Box textAlign="center">
                <Text fontSize={'lg'} color={textColor?.backgroundText}>
                  If you didn't recieve an email
                </Text>
                <Text
                  textAlign="center"
                  w="170px"
                  cursor={'grab'}
                  fontSize={'lg'}
                  ml={6}
                  color={textColor?.backgroundText}
                >
                  Click here to resend
                  <hr
                    style={{
                      border: `1px solid ${textColor?.foregroundText} `,
                    }}
                  />
                </Text>
              </Box>
            </Flex>
          </Box>
        </CardBox>
      </Stack>
    );
  };

  const emailNotSent = () => {
    return (
      <Stack
        align={'center'}
        pt={{ base: 10, md: 40 }}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
      >
        <CardBox>
          <Box mt={6}>
            <Flex mb={6} flexDirection={'column'} alignItems={'center'}>
              <InfoIcon boxSize={8} mb={4} color={textColor?.backgroundText} />

              <Heading mb={4} color={textColor?.backgroundText}>
                Forgot Password
              </Heading>

              <Text color={textColor?.backgroundText}>
                Don't worry, we'll help you get back in.
              </Text>
            </Flex>
            <Box mb={8}>
              <FormControl color={textColor?.backgroundText} isRequired>
                <FormLabel fontSize="2rem">Email</FormLabel>
                <Input
                  color={textColor?.backgroundText}
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  variant="flushed"
                  type="email"
                />
              </FormControl>
            </Box>

            <Flex
              mt={4}
              flexDirection="column"
              justify="space-around"
              align="center"
            >
              <Button
                borderRadius="30px"
                mb={4}
                w="100%"
                color={textColor?.backgroundText}
                bg={textColor?.foregroundText}
                onClick={() => sendEmailSubmit()}
              >
                Submit
              </Button>
            </Flex>
          </Box>
        </CardBox>
      </Stack>
    );
  };
  return (
    <Container maxW={'7x1'}>
      <Box display={sentEmail ? 'block' : 'none'}>{emailSent()}</Box>

      <Box display={!sentEmail ? 'block' : 'none'}>{emailNotSent()}</Box>
    </Container>
  );
};

export default ForgotPasswordSendEmailComponent;
