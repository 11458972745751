import React, { useState } from 'react';
import {
  Flex,
  Text,
  Button,
  Container,
  Textarea,
  Box,
  Input,
  Card,
  CardBody,
  CardHeader,
  IconButton,
  Heading,
  Avatar,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import FileUpload from '../FileUpload/FileUpload';
import { useUserStore } from '../../stores/useUserStore';
import EditTheme from './EditTheme';
import LandingPageComponent from '../StyleCompnents/LandingPageComponent';
import { userThemeStore } from '../../stores/useThemesStore';

const EditProfile = () => {
  const { userData, updateUserInfo, getUsers, isLoading } = useUserStore(
    (state: any) => state
  );
  const { theme } = userThemeStore((state: any) => state);
  const [edit, setEdit] = useState(false);
  const [userName, setUserName] = useState(userData?.userName || '');
  const [bio, setBio] = useState(userData?.bio || '');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = SelectedDefaultTextColor();
  const closeModal = () => {
    setEdit(false);
    setUserName(userData?.userName);
    setBio(userData?.bio);
    onClose();
  };

  const saveUpdatedInfo = async () => {
    await updateUserInfo({
      $id: userData.$id,
      data: {
        userName: userName,
        bio: bio,
      },
    });

    await getUsers();
    closeModal();
  };

  const viewUserInfo = () => {
    return (
      <Box>
        <CardHeader>
          <Flex>
            <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
              <Avatar size="2xl" src={userData?.userImage} />

              <Box>
                <Heading>
                  {userData?.userName ? userData?.userName : 'Add User Name'}
                </Heading>

                <Text fontSize="2xl">{userData?.name}</Text>
                <Text>{userData?.email}</Text>
              </Box>
            </Flex>

            <EditIcon
              onClick={onOpen}
              color={textColor?.backgroundText}
              mx={2}
            />
          </Flex>
        </CardHeader>
        <CardBody>
          <Heading mb={2}>BIO</Heading>
          <Text fontSize={'large'} fontFamily="Cambria">
            {userData?.bio}
          </Text>
        </CardBody>
      </Box>
    );
  };

  const updateUser = () => {
    return (
      <Box>
        <Card>
          <CardHeader>
            <Flex>
              <Flex
                flex="1"
                gap="4"
                alignItems="center"
                justifyContent={'center'}
                flexWrap="wrap"
              >
                <Flex>
                  <Avatar size="2xl" mr={4} mb={2} src={userData?.userImage} />
                </Flex>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Box mb={4}>
              <Heading mb={2} size="sm">
                User Name
              </Heading>
              <Input
                placeholder={'User Name'}
                borderColor={textColor?.foregroundText}
                variant="outline"
                onChange={e => setUserName(e.target.value)}
                value={userName}
              />
            </Box>
            <Heading mb={2} size="sm">
              Bio
            </Heading>
            <Textarea
              borderColor={textColor?.foregroundText}
              value={bio}
              onChange={e => setBio(e.target.value)}
            />
          </CardBody>
        </Card>
      </Box>
    );
  };

  return (
    <Box>
      <LandingPageComponent />
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 40 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex>
            <Card
              minH="400px"
              backgroundColor={'transparent'}
              border={!theme?.gradient ? '1px solid black' : ''}
              boxShadow="xl"
              maxW="lg"
              color={textColor?.backgroundText}
            >
              {viewUserInfo()}
            </Card>
          </Flex>
          <Box>
            <EditTheme />
          </Box>
        </Stack>
      </Container>
      <Modal isOpen={isOpen} onClose={() => closeModal()}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Profile Picture</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FileUpload uploadPage={'userProfile'} />
            {updateUser()}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => closeModal()}>
              Close
            </Button>
            {isLoading ? (
              <Button
                borderColor={textColor?.foregroundText}
                colorScheme="blue"
                mr={3}
                isLoading
                loadingText="Saving"
              ></Button>
            ) : (
              <Button
                borderColor={textColor?.foregroundText}
                onClick={() => saveUpdatedInfo()}
                variant="ghost"
              >
                Save
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default EditProfile;
