import React, { useEffect, useState } from 'react';
import { useUtilsStore } from '../../stores/useUtilsStore';
import {
  Box,
  Center,
  Flex,
  Image,
  Link,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';

const CssUtils = () => {
  const { getLinks, linkData } = useUtilsStore((state: any) => state);
  const [selectedOption, setSelectedOption] = useState('');
  const handleSelectChange = async event => {
    setSelectedOption(event.target.value);

    await getLinks(event.target.value);
  };
  const textColor = SelectedDefaultTextColor();
  useEffect(() => {
    const links = async () => {
      return await getLinks('generator');
    };

    links();
  }, []);

  return (
    <Box color={textColor?.backgroundText} w="100%">
      <Center>
        <Select
          w="200px"
          placeholder="Select option"
          value={selectedOption}
          onChange={handleSelectChange}
          mb={8}
        >
          <option
            style={{ color: textColor?.foregroundText }}
            value="generator"
          >
            <Text>Generator</Text>
          </option>
          <option style={{ color: textColor?.foregroundText }} value="tools">
            <Text>Tools</Text>
          </option>
          <option style={{ color: textColor?.foregroundText }} value="other">
            <Text color={'black'}>Other</Text>
          </option>
        </Select>
      </Center>
      <Stack
        align={'center'}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
      >
        {linkData?.map((link: any, index: number) => {
          return (
            <Flex key={index} flexDir={'column'} alignItems={'center'} w="100%">
              <Text fontSize="x-large" mb={2} fontFamily={'Goudy Old Style'}>
                {link?.siteName}
              </Text>
              <Image src={link?.imageUrl} boxSize="400px" />
              <Link href={link?.siteUrl} isExternal>
                {link?.siteUrl} <ExternalLinkIcon mx="2px" />
              </Link>
            </Flex>
          );
        })}
      </Stack>
    </Box>
  );
};

export default CssUtils;
