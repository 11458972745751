class CustomError extends Error {
    code: any;
    type: any;
    constructor(code, type, message) {
        super(message);
        this.code = code;
        this.type = type;
        this.name = 'CustomError';
    }
}

function throwErrorWithCodeAndMessage(code, type, message) {
    throw new CustomError(code, type, message);
}

// Export both the class and the function in a single statement
export { CustomError, throwErrorWithCodeAndMessage };