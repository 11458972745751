import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type { } from '@redux-devtools/extension'
import { getUserTheme, getAllThemes } from '../config/AppWrite/Apis/themeApi'

interface themeState {
    theme: any;
    themeName: string;
    allThemes: any
    setTheme: (theme: any) => void;
    setThemeName: (theme: any) => void;
    isLoading: boolean;
    userError: any;
    getTheme: (themeName: any) => void;

}

export const userThemeStore = create<themeState>()(
    devtools(
        persist(
            (set) => ({
                theme: {},
                themeName: '',
                isLoading: false,
                userError: '',
                allThemes: [],
                setThemeName: (themeName: any) => set({ themeName }),
                setTheme: (theme: any) => set({ theme }),
                getTheme: async (name: string) => {

                    try {
                        set({ isLoading: true });
                        const response = await getUserTheme(name);



                        set({ isLoading: false, theme: response.documents[0] });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                getThemes: async () => {

                    try {
                        set({ isLoading: true });
                        const response = await getAllThemes();
                        set({ isLoading: false, allThemes: response.documents });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                }
            })
            ,
            {
                name: 'theme-storage',
            },
        )
    )
)