import React from 'react';
import ForgotPasswordComponent from '../component/ForgotPassword/ForgotPasswordComponent';
import { Box } from '@chakra-ui/react';

export const ForgotPassword = () => {
  return (
    <Box>
      <ForgotPasswordComponent />
    </Box>
  );
};
