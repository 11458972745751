import { INewUser } from '../../../types/types';
import { ID, Query } from 'appwrite';
import { account, appWriteConfig, databases } from '../config';
import { CustomError, throwErrorWithCodeAndMessage } from '../../../utils/ErrorUtils';



export const createUserAccount = async (user: INewUser) => {
    try {
        const newAccount = await account.create(
            ID.unique(),
            user.email,
            user.password,
            user.name
        );
        if (!newAccount) throw Error;

        const newUser = await saveUserToDB({
            id: newAccount.$id,
            name: newAccount.name,
            email: newAccount.email,
            theme: process.env.REACT_APP_APPWRITE_DEFAULT_THEME || 'code dark',
            userName: user.userName || '',
            hasProvider: false,
            provider: null,
        });

        if (!newUser) throw Error;

        return newUser;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const verifyEmail = async email => {
    try {
        const verify = account.createVerification(email);
        return verify;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const createGoogleAccount = async () => {


    try {
        const provider = 'google';
        const googleAccount = await account.createOAuth2Session(
            provider,
            process.env.REACT_APP_APPWRITE_PROVIDER_VERIFICATION_PAGE
        );



        return googleAccount;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const providerDBVerification = async () => {
    const accountInfo = await account.get();

    const verifyEmail = await checkUserEmail(accountInfo?.email);

    try {
        if (verifyEmail.total === 0) {
            const newUser = await saveUserToDB({
                id: accountInfo?.$id,
                name: accountInfo?.name,
                email: accountInfo?.email,
                theme: process.env.REACT_APP_APPWRITE_DEFAULT_THEME || 'code dark',

                hasProvider: false,
                provider: null,
            });
            await account.get();
            return newUser;
        } else return;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const saveUserToDB = async (user: {
    id: string;
    name: string;
    email: string;
    theme: string;
    userName?: string;
    hasProvider: boolean;
    provider?: string | null;
}) => {
    try {
        const newUser = await databases.createDocument(
            appWriteConfig.databasesId || '',
            appWriteConfig.userCollectionId || '',
            ID.unique(),
            user
        );

        return newUser;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const SignInAccount = async (user: {
    email: string;
    password: string;
}) => {
    try {
        const session = await account.createEmailSession(user.email, user.password);

        return session;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getAccount = async () => {
    try {
        const currentAccount = await account.get();



        const getUser = await getUserFromDB(currentAccount.$id);


        return getUser;
    } catch (error: any) {

        throw new throwErrorWithCodeAndMessage(error.code, error.type, error.message);
    }
};
export const getUserFromDB = async (id: string) => {
    try {
        const currentUser = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.userCollectionId || '',
            [Query.equal('id', [id])]
        );

        return currentUser;
    } catch (err: any) {
        throw new Error(err);
    }
};
export const getUserFromEmail = async (email: string) => {
    try {
        const currentUser = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.userCollectionId || '',
            [Query.equal('email', [email])]
        );

        return currentUser;
    } catch (err: any) {
        throw new Error(err);
    }
};
export const getOauthAccount = async () => {
    try {
        const currentAccount = await account.getSession('current');


        const response = await fetch(
            'https://www.googleapis.com/oauth2/v2/userinfo',
            {
                headers: {
                    Authorization: `Bearer ${currentAccount.providerAccessToken}`,
                },
            }
        );

        if (response.ok) {
            const userInfo = await response.json();

            // Handle the user info as needed
        } else {
            console.error(
                'Failed to fetch Google user info:',
                response.status,
                response.statusText
            );
        }
        return currentAccount;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const signOutAccount = async () => {
    try {
        const session = account.deleteSessions();

        return session;
    } catch (error: any) {
        throw new Error(error);
    }
};

export const checkUserEmail = async (email: string) => {
    try {
        const checkEmail = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.userCollectionId || '',
            [Query.equal('email', [email])]
        );
        return checkEmail;
    } catch (err: any) {
        throw new Error(err);
    }
};
export const checkUserName = async (userName: string) => {
    try {
        const checkusername = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.userCollectionId || '',
            [Query.equal('userName', [userName])]
        );
        return checkusername;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const updateUser = async (user: {
    $id: string;

    data: any;
}) => {

    try {
        const update = await databases.updateDocument(
            appWriteConfig.databasesId,
            appWriteConfig.userCollectionId,
            user.$id,
            user.data
        );

        return update;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const createPassWordRecovery = (email: string) => {
    try {

        const recover = account.createRecovery(email, 'http://localhost:3000/forgotpassword')
        return recover
    } catch (err: any) {
        throw new Error(err)
    }

}

export const passwordRecoveryConfirmation = (id: string, secret: string, password: string, confirmPassword: string) => {

    try {
        const confirmation = account.updateRecovery(
            id,
            secret,
            password,
            confirmPassword
        )
        return confirmation
    } catch (err: any) {
        throw new Error(err)
    }
}
