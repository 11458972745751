import React from 'react';
import Ticker from 'framer-motion-ticker';
import { Box, Image } from '@chakra-ui/react';
import editFiles from '../../photos/slideshow/editfiles.png';
import editHome from '../../photos/slideshow/editHome.png';
import Files from '../../photos/slideshow/files.png';
import Folder from '../../photos/slideshow/folders.png';
import playgroundConsole from '../../photos/slideshow/playgroundConsole.png';
import playgroundjs from '../../photos/slideshow/playgroundjs.png';
import playgroundSave from '../../photos/slideshow/playgroundSave.png';
import { nanoid } from 'nanoid';
const ImageSlider = props => {
  const photosEdit = [editFiles, Files, Folder];
  const photosPlayground = [playgroundConsole, playgroundjs, playgroundSave];

  return (
    <Box py={4}>
      <Ticker duration={10} direction={props.direction === 'left' ? -1 : 1}>
        {props.direction === 'left'
          ? photosEdit?.map((item, index) => (
              <Box
                key={nanoid()}
                mr={20}
                style={{
                  backdropFilter: 'blur( 4.5px ) saturate(180%)',
                }}
                bg={'rgba(44, 63, 82, 0.75)'}
                p={2}
                boxShadow="2xl"
                rounded="md"
              >
                <Image boxSize={'250px'} src={item} />
              </Box>
            ))
          : photosPlayground?.map((item, index) => (
              <Box
                key={nanoid()}
                mr={20}
                style={{
                  backdropFilter: 'blur( 4.5px ) saturate(180%)',
                }}
                bg={'rgba(44, 63, 82, 0.75)'}
                p={2}
                boxShadow="2xl"
                rounded="md"
              >
                <Image boxSize={'250px'} src={item} />
              </Box>
            ))}
      </Ticker>
    </Box>
  );
};

export default ImageSlider;
