import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { } from '@redux-devtools/extension';
import {
    createGoogleAccount,
    createUserAccount,
    getAccount,
    providerDBVerification,
    SignInAccount,
    signOutAccount,
    verifyEmail,
    checkUserName,
    updateUser,
    createPassWordRecovery,
    passwordRecoveryConfirmation,

    getUserFromEmail,
} from '../config/AppWrite/Apis/userApi';
import { INewUser } from '../types/types';
import { userThemeStore } from './useThemesStore';
import { userCodeBlockStore } from './useCodeBlockStore';

interface userState {
    userTheme: string;
    userData: unknown;
    setTheme: (userTheme: string) => void;
    isLoading: boolean;
    userError: any;
}

export const useUserStore = create<userState>()(
    devtools(
        persist(
            set => ({
                userTheme: '',
                userData: [],
                isLoading: false,
                userError: null,
                setTheme: (userTheme: string) => set({ userTheme }),

                getUsers: async () => {
                    try {
                        set({ isLoading: true });
                        const response = await getAccount();

                        set({ isLoading: false, userData: response?.documents[0] });
                        const userThemeStoreInstance = await userThemeStore.getState(); // get the state of the userThemeStore
                        await userThemeStoreInstance.setThemeName(
                            response?.documents[0].theme
                        );


                        await userThemeStoreInstance.getTheme(
                            userThemeStoreInstance.themeName
                        );


                        return response?.documents[0];
                    } catch (err: any) {

                        if (err.code === 401) {
                            const userCodeBlockStoreInstance = await userCodeBlockStore.getState();

                            userCodeBlockStoreInstance.setCodeBlockDataList([])

                        }
                        set({ userError: err.message, isLoading: false });
                    }
                },

                signUpUser: async (user: INewUser) => {
                    try {
                        set({ isLoading: true });
                        const response = await createUserAccount(user);

                        set({ isLoading: false, userData: response });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                signUpAndSignInGoogleProvider: async (user: INewUser) => {
                    try {
                        set({ isLoading: true });
                        const response = await createGoogleAccount();

                        set({ isLoading: false, userData: response });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                signIn: async (user: INewUser) => {
                    try {
                        set({ userError: null, isLoading: true });
                        const response = await SignInAccount(user);
                        set({ isLoading: false, userData: response });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                providerVerification: async () => {
                    try {
                        set({ isLoading: true });
                        const response = await providerDBVerification();
                        set({ isLoading: false, userData: response });
                    } catch (err: any) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                signOutUser: async () => {
                    try {
                        set({ isLoading: true, userData: [] });

                        await signOutAccount();
                        set({ isLoading: false, userData: [] });
                    } catch (err) {
                        console.log(err);
                    }
                },
                verifyUserEmail: async (email: string) => {
                    try {
                        set({ isLoading: true });
                        await verifyEmail(email);
                        set({ isLoading: false });
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                verifyUserName: async (userName: string) => {
                    try {
                        set({ isLoading: true });
                        const checkUser = await checkUserName(userName);
                        set({ isLoading: false });
                        return checkUser
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                updateUserInfo: async (user: any) => {

                    try {
                        set({ isLoading: true });
                        const update = await updateUser(user)
                        set({ isLoading: false });
                        return update
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                passwordRecovery: async (email: any) => {
                    try {
                        set({ isLoading: true });
                        const recover = await createPassWordRecovery(email)
                        set({ isLoading: false });
                        return recover
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                passwordconfirm: async (id: any, secret: string, password: string, confirmPassword: string) => {


                    try {
                        set({ isLoading: true });
                        const recover = await passwordRecoveryConfirmation(id, secret, password, confirmPassword)
                        set({ isLoading: false });
                        return recover
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
                getUser: async (email: string) => {


                    try {
                        set({ isLoading: true });
                        const recover = await getUserFromEmail(email)
                        set({ isLoading: false });
                        return recover.documents
                    } catch (err) {
                        set({ userError: err.message, isLoading: false });
                    }
                },
            }),
            {
                name: 'user-storage',
            }
        )
    )
);
