import { INewUser } from '../../../types/types';
import { ID, Query } from 'appwrite';
import { account, appWriteConfig, databases } from '../config';

export const createBlock = async (codeBlock: {
    title: string;
    creator: string;
    category: string;
    subCategory?: string;
    javascript?: string;
    css?: string;
    html?: string;
    note?: string;
    tags?: [string];
    public?: boolean
}) => {
    try {
        const create = await databases.createDocument(
            appWriteConfig.databasesId || '',
            appWriteConfig.codeBlockCollectionId || '',
            ID.unique(),
            codeBlock
        );

        return create;
    } catch (err: any) {
        throw new Error(err);
    }
};
export const updateSingleCodeBlock = async (data: {
    $id?: string;
    title: string;
    creator: string;
    category: string;
    subCategory?: string;
    javascript?: string;
    css?: string;
    html?: string;
    note?: string;
    tags?: [string];
    public?: boolean
}) => {
    try {
        const update = await databases.updateDocument(
            appWriteConfig.databasesId || '',
            appWriteConfig.codeBlockCollectionId || '',
            data?.$id,
            data
        )

        return update

    } catch (err: any) {
        throw new Error(err)
    }
}
export const getSingleBlock = async (data: {
    id: string;
    category: string;
    creator: string;
}) => {
    try {
        const getBlock = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            [
                Query.equal('$id', [data?.id]),
                Query.equal('category', data?.category),
                Query.equal('creator', data?.creator),
            ]
        );

        return getBlock;
    } catch (err: any) {
        throw new Error(err);
    }
};
export const getCreatorBlock = async (creator: string) => {

    try {
        const getBlock = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            [Query.equal('creator', [creator])]
        );

        return getBlock;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const getRecentPublicCodeBlocks = async () => {

    try {

        const codeBlocks = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            [Query.equal('public', [true]),
            Query.orderDesc('$createdAt'),
            Query.limit(20)
            ])

        return codeBlocks
    } catch (err: any) {
        throw new Error(err)
    }
}
export const getBlockByCategory = async (category: {
    category1: string;
    category2?: string;
}) => {
    try {
        if (category?.category2) {
            const getBlock = await databases.listDocuments(
                appWriteConfig.databasesId,
                appWriteConfig.codeBlockCollectionId,

                [
                    Query.equal('category', [category.category1]),
                    Query.equal('subCategory', [category.category2]),
                ]
            );

            return getBlock;
        } else {
            const getBlock = await databases.listDocuments(
                appWriteConfig.databasesId,
                appWriteConfig.codeBlockCollectionId,

                [Query.equal('category', [category.category1])]
            );

            return getBlock;
        }
    } catch (err: any) {
        throw new Error(err);
    }
};
export const getUserBlockByCategory = async (creator: string, category?: any) => {


    try {

        const getBlock = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,

            [
                Query.equal('creator', [creator]),
                Query.equal('category', [category?.category])
            ]
        );

        return getBlock.documents;

    } catch (err: any) {
        throw new Error(err);
    }
};
export const getUserBlockByCategoryFolders = async (creator: string) => {


    try {

        const getBlock = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,

            [
                Query.equal('creator', [creator]),

            ]
        );
        const uniqueNames = [...new Set(getBlock.documents.map(doc => doc.category))].map(doc => { return { folder: doc } });


        return uniqueNames


    } catch (err: any) {
        throw new Error(err);
    }
};
export const getUserBlockByNoFolders = async (creator: string) => {


    try {

        const getBlock = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,

            [
                Query.equal('creator', [creator]),

            ]
        );

        const noCategory = getBlock.documents.filter(doc => doc?.category === "" || null)

        return noCategory


    } catch (err: any) {
        throw new Error(err);
    }
};

export const deleteBlock = async (id: string) => {
    try {
        const deleteBlock = await databases.deleteDocument(
            appWriteConfig.databasesId || '',
            appWriteConfig.codeBlockCollectionId || '',
            id

        );

        return deleteBlock;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const likeCodeBlock = async (id: string, favoritesArray: string[]) => {
    try {
        const updatedCodeBlock = await databases.updateDocument(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            id,
            {
                favorite: favoritesArray
            }
        )

        return updatedCodeBlock

    } catch (err: any) {
        throw new Error(err)
    }
}
export const savedCodeBlock = async (id: string, userId: string) => {
    try {
        const updatedCodeBlock = await databases.createDocument(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            ID.unique(),
            {
                user: userId,
                codeBlock: id
            }
        )

        return updatedCodeBlock

    } catch (err: any) {
        throw new Error(err)
    }
}
export const deleteSavedCodeBlock = async (savedCodeBlockId: string) => {
    try {
        const deleteSavedCode = await databases.deleteDocument(
            appWriteConfig.databasesId,
            appWriteConfig.codeBlockCollectionId,
            savedCodeBlockId,

        )

        return deleteSavedCode

    } catch (err: any) {
        throw new Error(err)
    }
}


