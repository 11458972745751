
import { userThemeStore } from '../stores/useThemesStore';
import { tags as t } from '@lezer/highlight';
import { createTheme } from '@uiw/codemirror-themes';

export const SelectedBackgroundColor = () => {
    const { theme } = userThemeStore((state: any) => state);

    let backgroundColor = '';



    if (theme?.gradient) {
        backgroundColor = `linear(to-r, ${theme?.backgroundGradient[0]}, ${theme?.backgroundGradient[1]}, ${theme?.backgroundGradient[2]})`;
    } else if (theme?.backgroundImage) {
        backgroundColor = theme?.backgroundImage

    }

    else if (theme?.gradient === false && theme?.background === false) {
        backgroundColor = theme?.backgroundSolid;
    } else backgroundColor = 'default.backgroundSolid';
    return backgroundColor;
};

export const SelectedDefaultTextColor = () => {
    const { theme } = userThemeStore((state: any) => state);

    let defaultTextColor = {
        backgroundText: '',
        foregroundText: '',
    };
    if (!theme?.backGroundText) {
        defaultTextColor.backgroundText = 'black';
        defaultTextColor.backgroundText = '#ffffff';
        defaultTextColor.foregroundText = '#893557';
    } else
        defaultTextColor = {
            backgroundText: theme?.backGroundText,
            foregroundText: theme?.foregroundText,
        };
    return defaultTextColor;
};

export const editorTheme = createTheme({
    theme: 'dark',
    settings: {
        background: 'transparent',
        backgroundImage: '',

        foreground: 'white',
        caret: '#AEAFAD',
        selection: '#D6D6D6',
        selectionMatch: '#D6D6D6',
        gutterBackground: 'transparent',
        gutterForeground: 'white',
        gutterBorder: '#dddddd',
        gutterActiveForeground: '',
        lineHighlight: '#FFFFFF10',
    },
    styles: [
        { tag: t.comment, color: '#787b80' },
        { tag: t.definition(t.typeName), color: '#194a7b' },
        { tag: t.typeName, color: '#194a7b' },
        { tag: t.tagName, color: '#008a02' },
        { tag: t.variableName, color: '#white' },
    ],
});