import React, { useState } from 'react';
import { StarIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import { useUserStore } from '../../stores/useUserStore';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';
import { SaveOutline } from 'react-ionicons';
import { useNavigate } from 'react-router-dom';

const CardFooterInfo = ({ cardInfo }) => {
  const favoriteList = cardInfo.favorite?.map(user => user.$id);
  const [favorites, setFavorites] = useState(favoriteList);

  const { userData } = useUserStore((state: any) => state);
  const {
    favoriteCode,

    getRecentPublic,
    setEditCodeBlock,
  } = userCodeBlockStore((state: any) => state);
  const textColor = SelectedDefaultTextColor();
  const addFavoriteItem = async () => {
    let newFavorites = [...favorites];

    const isFavorite = newFavorites.includes(userData.$id);

    if (isFavorite) {
      newFavorites = newFavorites.filter(id => id !== userData.$id);
    } else {
      newFavorites.push(userData.$id);
    }
    setFavorites(newFavorites);
    await favoriteCode(cardInfo.$id, newFavorites);
    await getRecentPublic();
  };

  const navigate = useNavigate();
  const editCode = async (
    title: string,
    note: string,
    javascript: string,
    css: string,
    html: string,
    edit: boolean
  ) => {
    localStorage.setItem(
      'DevErNote-current-notetitle',
      JSON.stringify(title || '')
    );
    localStorage.setItem('DevErNote-current-note', JSON.stringify(note || ''));
    localStorage.setItem(
      'DevErNote-current-notejavascript',
      JSON.stringify(javascript || '')
    );
    localStorage.setItem(
      'DevErNote-current-notecss',
      JSON.stringify(css || '')
    );

    localStorage.setItem(
      'DevErNote-current-notehtml',
      JSON.stringify(html || '')
    );

    // await getUserCodeBlockByCategies(userData?.email, codeBlockData?.category);

    setEditCodeBlock(edit);
    navigate('/playground');
  };
  return (
    <Flex
      color={textColor?.backgroundText}
      justifyContent={'space-between'}
      sx={{
        '& > button': {
          minW: '136px',
        },
      }}
    >
      <Button
        onClick={() => addFavoriteItem()}
        color={textColor?.backgroundText}
        flex="1"
        variant="ghost"
        leftIcon={<StarIcon color={favorites?.length > 0 ? 'red' : 'white'} />}
        _hover={{ bg: 'transparent' }}
      >
        Like {favorites.length}
      </Button>
      {/* <Button
        color={textColor?.backgroundText}
        flex="1"
        variant="ghost"
        leftIcon={<ChatIcon />}
        _hover={{ bg: 'transparent' }}
      >
        Comments 0
      </Button> */}
      <Button
        color={textColor?.backgroundText}
        flex="1"
        variant="ghost"
        leftIcon={<SaveOutline color={'#ffffff'} />}
        _hover={{ bg: 'transparent' }}
        onClick={() =>
          editCode(
            cardInfo.title,
            cardInfo.note,
            cardInfo.javascript,
            cardInfo.css,
            cardInfo.html,
            false
          )
        }
      >
        PlayGround
      </Button>
    </Flex>
  );
};

export default CardFooterInfo;
