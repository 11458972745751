import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  Image,
  Container,
  Stack,
  Center,
  Heading,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useUserStore } from '../../stores/useUserStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import { InfoIcon } from '@chakra-ui/icons';

const ForgotPasswordComponent = () => {
  const { passwordconfirm } = useUserStore((state: any) => state);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updatedPassword, setUpdatedPassword] = useState(false);
  const [error, setError] = useState('');
  const [secret, setSecret] = useState('');
  const [userId, setUserId] = useState('');
  const location = useLocation();

  const textColor = SelectedDefaultTextColor();

  useEffect(() => {
    // Parse the query parameters from the URL
    const params = new URLSearchParams(location.search);
    setUserId(params.get('userId'));
    setSecret(params.get('secret'));
  }, [location.search]);

  useEffect(() => {
    if (password !== confirmPassword) {
      setError('Password does not match');
    } else setError('');
  }, [password, confirmPassword]);

  const submitUpdatedPassword = async () => {
    try {
      await passwordconfirm(userId, secret, password, confirmPassword);
      setUpdatedPassword(true);
    } catch (err) {
      console.log(err);
    }
  };
  const passwordChanged = () => {
    return (
      <Stack
        align={'center'}
        pt={{ base: 10, md: 40 }}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
      >
        <Box w="25%">
          <Flex mb={6} flexDirection={'column'} alignItems={'center'}>
            <InfoIcon mb={4} color={textColor?.backgroundText} />

            <Heading mb={4} color={textColor?.backgroundText}>
              Password has been reset
            </Heading>

            <Text mb={4} color={textColor?.backgroundText}>
              CLick below to log in
            </Text>
            <Button
              borderRadius="30px"
              mb={4}
              w="50%"
              color={textColor?.backgroundText}
              bg={textColor?.foregroundText}
              onClick={() => console.log('click')}
            >
              <Link to="/signIn">Sign In</Link>
            </Button>
          </Flex>
        </Box>
      </Stack>
    );
  };

  const changePassword = () => {
    return (
      <Stack
        align={'center'}
        pt={{ base: 10, md: 40 }}
        spacing={{ base: 8, md: 10 }}
        direction={{ base: 'column', md: 'row' }}
        justifyContent={'center'}
      >
        <Box>
          <Flex mb={6} flexDirection={'column'} alignItems={'center'}>
            <InfoIcon boxSize={8} mb={4} color={textColor?.backgroundText} />

            <Heading mb={4} color={textColor?.backgroundText}>
              Forgot Password
            </Heading>

            <Text color={textColor?.backgroundText}>
              New password must be different from previous passwords.
            </Text>
          </Flex>
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Box w="90%" mb={6}>
              <FormControl
                color={textColor?.backgroundText}
                id="password"
                isRequired
              >
                <FormLabel fontSize="2rem">Password</FormLabel>
                <Input
                  color={textColor?.backgroundText}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    setError('');
                  }}
                  variant="flushed"
                  type="password"
                />
              </FormControl>
            </Box>
            <Box w="90%" pb={6}>
              <FormControl
                isInvalid={error?.length > 0 && true}
                color={textColor?.backgroundText}
                id="password"
                isRequired
              >
                <FormLabel fontSize="2rem">Confirm</FormLabel>
                <Input
                  color={textColor?.backgroundText}
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    setError('');
                  }}
                  variant="flushed"
                  type="password"
                />
                {error?.length < 1 ? (
                  <FormHelperText>Password must match</FormHelperText>
                ) : (
                  <FormErrorMessage>Passwords don't Match.</FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </Flex>

          <Flex
            mt={4}
            flexDirection="column"
            justify="space-around"
            align="center"
          >
            <Button
              borderRadius="30px"
              mb={4}
              w="50%"
              color={textColor?.backgroundText}
              bg={textColor?.foregroundText}
              onClick={() => submitUpdatedPassword()}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      </Stack>
    );
  };

  return (
    <Container maxW={'7x1'}>
      <Box display={updatedPassword ? 'block' : 'none'}>
        {passwordChanged()}
      </Box>
      <Box display={!updatedPassword ? 'block' : 'none'}>
        {changePassword()}
      </Box>
    </Container>
  );
};

export default ForgotPasswordComponent;
