import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading, Avatar, Text, IconButton } from '@chakra-ui/react';
import { useUserStore } from '../../stores/useUserStore';
import { IUser } from '../../types/types';

const CardHeaderInfo = ({ userInfo }) => {
  const { getUser } = useUserStore((state: any) => state);
  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    const userinfo = async () => {
      const userResult = await getUser(userInfo?.creator);
      // Assuming `userResult` is the data you want to set as the user

      setUser(userResult[0]);
    };

    userinfo(); // Call the async function
  }, []);

  return (
    <Box p={6}>
      <Flex>
        <Flex flex="1" gap="4" alignItems="center" flexWrap="wrap">
          <Avatar name={user?.name} src={user?.userImage} />

          <Box>
            <Heading size="sm">
              {user?.userName ? user?.userName : user?.name}
            </Heading>
            <Text>{user?.email}</Text>
          </Box>
        </Flex>
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="See menu"
          // icon={<BsThreeDotsVertical />}
        />
      </Flex>
    </Box>
  );
};

export default CardHeaderInfo;
