import { ID, Query } from 'appwrite';
import { account, appWriteConfig, databases, storage } from '../config';
import { IUtils } from '../../../types/types';


export const uploadLinkInfo = async (data: IUtils) => {

    try {
        const newUrlInfo = await databases.createDocument(
            appWriteConfig.databasesId,
            appWriteConfig.utilsCssCollectionId,
            ID.unique(),
            data
        )

        return newUrlInfo
    } catch (error: any) {
        throw new Error(error)
    }

}

export const getLinkInfoByType = async (data: string) => {

    try {
        const linkType = await databases.listDocuments(
            appWriteConfig.databasesId,
            appWriteConfig.utilsCssCollectionId,
            [
                Query.equal('type', [data])
            ]
        )

        return linkType
    } catch (err: any) {
        throw new Error(err)
    }
}

export const getLinksFileId = async (data: string) => {
    try {
        const getFile = await storage.getFilePreview(
            appWriteConfig.userImageStorageId,
            data
        );


        return getFile;
    } catch (err: any) {
        throw new Error(err);
    }
};