import { useState, useEffect } from 'react';

export const useIsMobileScreen = () => {
  const checkIsMobile = () => window.matchMedia('(max-width: 768px)').matches;

  const [isMobile, setIsMobile] = useState(checkIsMobile());

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(checkIsMobile());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};
