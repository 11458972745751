import { create, } from 'zustand';

import {
    uploadFile,
    getFileId,
    savePhotoUrl,

    getPhoto,
    deletePhoto,
    updatePhoto,
} from '../config/AppWrite/Apis/imageApi';


export const useImageStore = create(set => ({
    imageData: [],
    imageResponse: [],


    getImage: async (id: string) => {
        try {
            set({ isLoading: true });
            const response = await getPhoto(id);
            set({ isLoading: false, imageResponse: response });
            return response;
        } catch (err: any) {
            set({ imageError: err.message, isLoading: false });
        }
    },
    getFilePreview: async (id: string) => {

        try {
            const response = await getFileId(id);
            return response;
        } catch (err) {
            throw new Error(err);
        }
    },
    updateDescription: async (id: string, description: string) => {
        try {
            set({ isLoading: true });
            const response = await updatePhoto(id, description);

            set({ isLoading: false, imageResponse: response });
            return response;
        } catch (err: any) {
            set({ imageError: err.message });
        }
    },
    deleteFile: async (id: string) => {
        try {
            set({ isLoading: true });
            await deletePhoto(id);

            set({ isLoading: false, imageData: [] });
        } catch (err: any) {
            set({ imageError: err.message, isLoading: false });
        }
    },
    addFile: async (file: File) => {
        try {
            set({ isLoading: true });
            const response = await uploadFile(file);
            set({ isLoading: false, imageResponse: response });

            return response;
        } catch (err) {
            throw new Error(err);
        }
    },
    saveUrl: async (url: any) => {

        try {
            set({ isLoading: true });
            const response = await savePhotoUrl(url);
            set({ isLoading: false, imageResponse: response });
            return response;
        } catch (err) {
            console.log(err);
            throw new Error(err)
        }
    },
    imageError: null,
    isLoading: false,
}));