import React from 'react';
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Container,
  Box,
  Heading,
  Stack,
  Center,
} from '@chakra-ui/react';
import { SelectedDefaultTextColor } from '../utils/colorSelection';

import CssUtils from '../component/Utils/CssUtils';

const Utils = () => {
  const textColor = SelectedDefaultTextColor();
  return (
    <Box>
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 14 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Box>
            <Center>
              <Heading
                color={textColor?.backgroundText}
                fontFamily={'Georgia'}
                mb={6}
              >
                WebDev Wisdom
              </Heading>
            </Center>
            <Text
              fontFamily={'Goudy Old Style'}
              fontSize="x-large"
              color={textColor?.backgroundText}
            >
              Unlock coding brilliance with curated tips for CSS, JS, and HTML.
              Elevate your web development skills effortlessly.
            </Text>
            <Box mt={16}>
              <Tabs isFitted variant="enclosed">
                <TabList
                  fontSize={'xl'}
                  color={textColor?.backgroundText}
                  mb="1em"
                >
                  <Tab fontSize={'xl'}>CSS</Tab>
                  <Tab fontSize={'xl'}>HTML</Tab>
                  <Tab fontSize={'xl'}>Javascript</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel w="100%">
                    <Flex h="100%">
                      <CssUtils />
                    </Flex>
                  </TabPanel>
                  <TabPanel>
                    <p>Html</p>
                  </TabPanel>
                  <TabPanel>
                    <p>Javascript</p>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Utils;
