'use client';
import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Button,
  Box,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Image,
  Spinner,
  Checkbox,
} from '@chakra-ui/react';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';
import { useUserStore } from '../../stores/useUserStore';
import {
  SelectedBackgroundColor,
  SelectedDefaultTextColor,
} from '../../utils/colorSelection';
import openFolders from '../../photos/open-folder.png';
import documents from '../../photos/documents.png';

const SaveCodeModal = (props: {
  title: string;
  html: string;
  css: string;
  javascript: string;
  note: string;
  setTitle: any;
}) => {
  const [folderCategory, setFolderCategory] = useState('');
  const [setCodeBlockStateData] = useState([]);
  const [showBackButton, setShowBackButton] = useState(false);
  const [makePublic, setMakePublic] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { title, html, css, javascript, note, setTitle } = props;
  const {
    createCodeBlock,
    getUserCodeBlockByCategies,
    updateCodeBlock,
    codeBlockDataList,
    setEditCodeBlock,

    getUserCodeBlockFolders,
    getUserCodeBlockNoFolder,
    isCodeBlockLoading,
    codeBlockData,
    editCodeBlock,
  } = userCodeBlockStore((state: any) => state);
  const { userData } = useUserStore((state: any) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = SelectedDefaultTextColor();
  useEffect(() => {
    if (editCodeBlock) {
      setEditMode(true);
      const getFilesInEditView = async () => {
        await getUserCodeBlockByCategies(userData.email, {
          category: codeBlockData?.category,
        });
      };
      getFilesInEditView();

      setFolderCategory(codeBlockData?.category);
    }

    if (codeBlockData) {
      setMakePublic(codeBlockData?.public);
    }
  }, []);

  const saveCodeBlock = async () => {
    if (editCodeBlock) {
      try {
        await updateCodeBlock({
          $id: codeBlockData?.$id,
          title: title,
          creator: userData?.email,
          category: folderCategory,
          subCategory: '',
          html: html,
          css: css,
          javascript: javascript,
          note: note,
          tags: [''],
          public: makePublic,
        });
        setEditCodeBlock(false);
        onClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await createCodeBlock({
          title: title,
          creator: userData?.email,
          category: folderCategory,
          subCategory: '',
          html: html,
          css: css,
          javascript: javascript,
          note: note,
          tags: [''],
          public: makePublic,
        });
        onClose();
      } catch (err) {
        console.log('the create err', err);
      }
    }
  };

  const shortString = (
    str: string | undefined | null
  ): string | undefined | null => {
    return str?.slice(0, 10);
  };

  const openFolder = async (item: any) => {
    await getUserCodeBlockByCategies(userData.email, { category: item });
    setShowBackButton(true);
  };

  const handleSave = async () => {
    onOpen();
    setShowBackButton(false);
    try {
      if (!editMode) {
        await getUserCodeBlockFolders(userData.email);
      }
    } catch (err: any) {
      console.log('save err', err);
    }
  };

  return (
    <div>
      <Button
        // w="150px"

        ml={4}
        size={'md'}
        fontWeight={'normal'}
        px={6}
        variant="ghost"
        _hover={{ borderColor: textColor?.foregroundText }}
        boxShadow="2xl"
        color={textColor?.backgroundText}
        onClick={() => {
          handleSave();
        }}
        isDisabled={!userData?.email ? true : false}
      >
        {' '}
        &#10148; Save
      </Button>

      <Modal
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          setMakePublic(false);
          setEditCodeBlock(false);
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent
          bg={'default.backgroundSolid'}
          bgGradient={SelectedBackgroundColor()}
          color={textColor?.backgroundText}
        >
          <ModalHeader>Save</ModalHeader>
          <Flex alignItems={'center'}>
            <ModalHeader>Title:</ModalHeader>
            <Input
              value={title}
              onChange={e => setTitle(e.target.value)}
              variant="flushed"
            />
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            <Flex mb={3} alignItems={'center'} justifyContent={'space-between'}>
              <Text>Save to: {folderCategory}</Text>
              <Flex>
                {showBackButton && (
                  <Button
                    onClick={() => {
                      getUserCodeBlockFolders(userData.email);
                      setShowBackButton(false);
                    }}
                    color="black"
                    mt={2}
                    ml={4}
                    rounded={'full'}
                    size={'md'}
                    fontWeight={'normal'}
                    px={6}
                    // bg={colorMode === 'light' ? TextColor1() : TextColor2()}
                    _hover={{ bg: 'blue.500' }}
                  >
                    &#10229; Go Back
                  </Button>
                )}
                <Popover closeOnBlur={false}>
                  {({ isOpen, onClose }) => (
                    <>
                      <PopoverTrigger>
                        <Button
                          color="black"
                          mt={2}
                          ml={4}
                          rounded={'full'}
                          size={'md'}
                          fontWeight={'normal'}
                          px={6}
                          // bg={colorMode === 'light' ? TextColor1() : TextColor2()}
                          _hover={{ bg: 'blue.500' }}
                        >
                          New Folder
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent w="400px" color="black">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Folder Name</PopoverHeader>
                        <PopoverBody>
                          <Flex justifyContent="space-between">
                            <Input
                              value={folderCategory}
                              onChange={e => {
                                setFolderCategory(e.target.value);
                              }}
                              w="250px"
                              placeholder="Folder Name"
                            />

                            <Button variant="outline" onClick={onClose}>
                              Submit
                            </Button>
                          </Flex>
                        </PopoverBody>
                      </PopoverContent>
                    </>
                  )}
                </Popover>
              </Flex>
            </Flex>
            <Flex
              flexWrap="wrap"
              h="400px"
              maxWidth="100%"
              border="solid"
              borderColor="white"
              flexDirection="row"
              justifyContent="flex-start"
            >
              {codeBlockDataList && !editCodeBlock
                ? codeBlockDataList?.map((item: any, index) => (
                    <Flex
                      onClick={() => {
                        if (item.folder?.length > 0) {
                          openFolder(item.folder);
                          setTitle(
                            item.folder && item.folder !== ''
                              ? item.folder
                              : '*No category'
                          );
                          setFolderCategory(item?.folder);
                        } else {
                          getUserCodeBlockNoFolder(userData.email);
                          setTitle(item.title);
                          setShowBackButton(true);
                        }
                      }}
                      maxWidth="100%"
                      key={index}
                      mt={5}
                      color="white"
                      flexWrap="wrap"
                      flexDirection="column"
                      alignItems="center"
                    >
                      {isCodeBlockLoading ? (
                        <Spinner key={index} mt={4} ml={4} mr={20} size="xl" />
                      ) : (
                        <Flex
                          key={index}
                          ml={2}
                          direction={'column'}
                          alignItems="center"
                        >
                          <Image
                            src={
                              item?.folder || item?.folder === ''
                                ? openFolders
                                : documents
                            }
                            alt="Image"
                            width={100}
                            height={100}
                          />
                          <Box>
                            {item?.folder
                              ? shortString(item.folder)
                              : item?.folder === ''
                              ? '*No Folder'
                              : shortString(item.title)}
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                  ))
                : codeBlockDataList?.map((item: any, index) => (
                    <Flex>
                      {isCodeBlockLoading ? (
                        <Spinner key={index} mt={4} ml={4} mr={20} size="xl" />
                      ) : (
                        <Flex
                          key={index}
                          ml={2}
                          direction={'column'}
                          alignItems="center"
                        >
                          <Image
                            src={documents}
                            alt="Image"
                            width={100}
                            height={100}
                          />
                          <Box>{shortString(item?.title)}</Box>
                        </Flex>
                      )}
                    </Flex>
                  ))}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Flex w="100%" justifyContent={'space-between'}>
              <Checkbox onChange={e => setMakePublic(!makePublic)}>
                Make Public
              </Checkbox>
              <Box>
                <Button
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    setMakePublic(false);
                    setEditCodeBlock(false);
                    onClose();
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    saveCodeBlock();
                    setEditCodeBlock(false);
                    onClose();
                  }}
                >
                  Save
                </Button>
              </Box>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SaveCodeModal;
