import { Box } from '@chakra-ui/react';
import React from 'react';
import EditProfile from '../component/Profile/EditProfile';

const Profile = () => {
  return (
    <Box>
      <EditProfile />
    </Box>
  );
};

export default Profile;
