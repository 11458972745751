import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Image,
} from '@chakra-ui/react';

import { useUserStore } from '../../stores/useUserStore';
import { userThemeStore } from '../../stores/useThemesStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import google from '../../photos/google.png';

const SignInComponent = () => {
  const {
    signIn,
    signUpAndSignInGoogleProvider,
    userError,
    userData,
    getUsers,
  } = useUserStore((state: any) => state);
  const { theme, themeName, setTheme, setThemeName, getTheme } = userThemeStore(
    (state: any) => state
  );

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();
  const textColor = SelectedDefaultTextColor();
  const handleSubmit = async () => {
    if (!isValidEmail(email)) {
      setError('Invalid email address');
      return;
    }

    if (password.length < 5) {
      setError('Password must be at least 5 characters long');
      return;
    }

    try {
      await signIn({
        email: email,
        password: password,
      });

      if (!userData.name) {
        await getUsers();

        await setThemeName(userData?.theme);
        await getTheme(themeName);

        navigate('/');
      }
    } catch (err) {
      console.log('sign in error', err);
      toast({
        title: 'Sign in Error',
        description: userError,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const isValidEmail = (email: string) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  return (
    <Box>
      <Box>
        <FormControl color={textColor?.backgroundText} id="email" isRequired>
          <FormLabel color="white" fontSize="2rem">
            Email
          </FormLabel>

          <Input
            value={email}
            // color={textColor?.backgroundText}
            onChange={e => {
              setEmail(e.target.value);
              setError('');
            }}
            borderColor={'ButtonFace'}
            variant="flushed"
            type="email"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl color="white" id="password" isRequired mt={6}>
          <FormLabel fontSize="2rem">Password</FormLabel>
          <Input
            color={textColor?.backgroundText}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setError('');
            }}
            borderColor={''}
            variant="flushed"
            type="password"
          />
        </FormControl>
      </Box>
      <Box>
        <Text>{error}</Text>
      </Box>
      <Flex mt={4} flexDirection="column" justify="space-around" align="center">
        <Button
          borderRadius="30px"
          mb={4}
          w="100%"
          color={textColor?.backgroundText}
          bg={textColor?.foregroundText}
          onClick={handleSubmit}
        >
          Submit
        </Button>

        <Button
          bg={'orange'}
          borderRadius="30px"
          mb={4}
          w="100%"
          color="white"
          onClick={() => signUpAndSignInGoogleProvider()}
        >
          <Text mr={2}>Sign In With</Text>
          <Image
            src={google}
            alt="google"
            width={30}
            height={30}
            // className="md:h-auto md:w-full"
          />
        </Button>
        <Link to={'/forgotpasswordemail'}>
          <Text color={textColor?.backgroundText}>Forgot Password</Text>
        </Link>
      </Flex>
    </Box>
  );
};

export default SignInComponent;
