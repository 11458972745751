import React from 'react';
import { Box } from '@chakra-ui/react';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';

interface CardBoxProps {
  children: React.ReactNode;
}

const CardBox: React.FC<CardBoxProps> = ({ children }) => {
  const defaultTextColor = SelectedDefaultTextColor();
  return (
    <Box
      color={defaultTextColor?.backgroundText}
      rounded="md"
      border={'1px solid rgba(255, 255, 255, 0.125)'}
      borderRadius={10}
      style={{
        backdropFilter: 'blur( 4.5px ) saturate(180%)',
      }}
      px={6}
      bg={'rgba(44, 63, 82, 0.75)'}
      boxShadow="xl"
      mb={6}
    >
      {children}
    </Box>
  );
};

export default CardBox;
