'use client';
import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import CodeMirror from '@uiw/react-codemirror';
import { Text, Flex, Button, Box, Image } from '@chakra-ui/react';
// import vdoublearrow from '../../photos/vdoublearrow.svg';
import hdoublearrow from '../../photos/hdoublearrow.svg';
import {
  SelectedDefaultTextColor,
  editorTheme,
} from '../../utils/colorSelection';
import { html } from '@codemirror/lang-html';
// import { aura } from '@uiw/codemirror-theme-aura';
// import { nord } from '@uiw/codemirror-theme-nord';

const HtmlEditor = (props: { setHtmlValue: any; value: string }) => {
  const { setHtmlValue, value } = props;
  let location = useLocation();
  const [open, setOpen] = useState(true);
  const [openVerticle, setOpenVerticle] = useState(true);

  const onChange = useCallback((val: string, viewUpdate: any) => {
    setHtmlValue(val);
    if (typeof window !== 'undefined') {
      localStorage.setItem('DevErNote-current-notehtml', JSON.stringify(val));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const textColor = SelectedDefaultTextColor();
  const htmlValue = () => {
    if (typeof window !== 'undefined') {
      const jsonValue = localStorage.getItem('DevErNote-current-notehtml');
      return jsonValue != null ? JSON.parse(jsonValue) : '';
    } else return '';
  };
  return (
    <Flex
      flexDirection="column"
      width={{ md: open ? '100%' : '20%', base: open ? '100%' : '100%' }}
    >
      <Flex
        pl={3}
        pr={{ base: 10, md: 0 }}
        w="100%"
        justifyContent="space-between"
      >
        <Text fontSize="1.8rem" color={textColor?.backgroundText}>
          HTML
        </Text>
        {location.pathname !== '/home' &&
          location.pathname !== '/socialhub' && (
            <Button
              display={{ base: 'none', md: 'block' }}
              _hover={{ bg: textColor?.foregroundText }}
              variant={'ghost'}
              onClick={() => setOpen(prevOpen => !prevOpen)}
            >
              <Image alt="arrow" src={hdoublearrow} width={30} height={30} />
            </Button>
          )}
        {location.pathname !== '/home' && (
          <Button
            display={{ base: 'none', md: 'block' }}
            _hover={{ bg: textColor?.foregroundText }}
            variant={'ghost'}
            onClick={() => setOpen(prevOpen => !prevOpen)}
          >
            <Image alt="arrow" src={hdoublearrow} width={30} height={30} />
          </Button>
        )}
      </Flex>
      <Box
        rounded="md"
        display={!openVerticle ? 'none' : 'block'}
        border={'1px solid rgba(255, 255, 255, 0.125)'}
        borderRadius={10}
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        px={2}
        bg={'rgba(44, 63, 82, 0.75)'}
        boxShadow="2xl"
      >
        <CodeMirror
          value={location.pathname === '/playground' ? htmlValue() : value}
          height="300px"
          extensions={[html()]}
          onChange={onChange}
          theme={editorTheme}
        />
      </Box>
    </Flex>
  );
};

export default HtmlEditor;
