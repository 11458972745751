import SignUpComponent from '../component/SignUp/SignUpComponent';
import { Container, Stack, Text, Box, Image, Flex } from '@chakra-ui/react';

import { SelectedDefaultTextColor } from '../utils/colorSelection';
import devPic from '../photos/22.png';

const SignUp = () => {
  const textColor = SelectedDefaultTextColor();
  return (
    <Box h="100%">
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 10, md: 18 }}
          direction={{ base: 'column', md: 'row' }}
          justifyContent={'center'}
        >
          <Box display={{ base: 'none', md: 'block' }} w="50%">
            <Text fontSize="4xl" color={textColor?.backgroundText}>
              DevErNote
            </Text>
            <Text color={textColor?.backgroundText}>
              {' '}
              Save your Code snippets for ease of access,have one you like,
              share it!
            </Text>
            <Image alt={'dev picture'} w={'100%'} h={'100%'} src={devPic} />
          </Box>
          <Flex
            direction="column"
            align={'center'}
            h="500px"
            w={{ base: '100%', md: '50%' }}
            // border="1px"
            borderColor={textColor?.backgroundText}
          >
            <Text color={textColor?.backgroundText} fontSize="3xl">
              Register
            </Text>
            <Flex
              direction="column"
              borderColor={textColor?.backgroundText}
              h="100%"
              w="70%"
              justify="space-around"
            >
              <SignUpComponent />
            </Flex>
          </Flex>
        </Stack>
      </Container>
    </Box>
  );
};

export default SignUp;
