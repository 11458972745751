import { Box, Stack, Flex } from '@chakra-ui/react';
import React from 'react';
import CodeBlockView from '../component/SocialHub/CodeBlockView';

const Social = () => {
  return (
    <Box w="100%">
      <Flex w="100%" direction={'row'} justifyContent={'space-between'}>
        <Box w="100%">Friends</Box>
        <Box w="100%">
          <CodeBlockView />
        </Box>
        <Box w="100%">Tags</Box>
      </Flex>
    </Box>
  );
};

export default Social;
