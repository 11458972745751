import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Center,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SelectedDefaultTextColor } from '../utils/colorSelection';
import StandingPic from '../photos/standingdev-PhotoRoom.png';

import LandingPageComponent from '../component/StyleCompnents/LandingPageComponent';

import ImageSlider from '../component/StyleCompnents/ImageSlider';
import Video from '../photos/devTextBack2.mp4';
import './landingpage.css';
import { useIsMobileScreen } from '../utils/screenUtils';

const LandingPage = (props: any) => {
  const isMobile = useIsMobileScreen();
  const textColor = SelectedDefaultTextColor();

  return (
    <Box>
      <LandingPageComponent />
      <Container maxW={'7xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 20, md: 20 }}
          pb={{ base: 20, md: 10 }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Stack
            flex={1}
            spacing={{ base: 5, md: 10 }}
            // display={{ base: 'hidden', md: '' }}
          >
            {isMobile ? (
              <Box>
                {/* <video
                autoPlay
                loop
                muted
                style={{
                  position: 'relative',
                  width: '560px',
                  height: '200px',
                  // zIndex: '-1',
                  objectFit: 'cover',
                  paddingLeft: '5px',
                }}
                >
                <source src={Video} type="video/mp4" />
                Your browser does not support the video tag.
                </video> */}
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  // fontSize="7xl"
                  fontSize={{ base: '5xl', sm: '5xl', lg: '7xl' }}
                  style={{
                    // position: 'absolute',
                    // top: 143,
                    // backgroundColor: '#2c3f52',
                    // mixBlendMode: 'darken',
                    color: '#ffffff',
                    height: '205px',
                    width: '100%',
                  }}
                  color="transparent"
                >
                  <Box m={2}>
                    <Text
                      className="textColor"
                      fontFamily={'Georgia'}
                      as={'span'}
                      position={'relative'}

                      // textShadow={'3px 3px 0 #000'}
                    >
                      Developer
                    </Text>
                  </Box>

                  <Text m={2} fontFamily={'Georgia'} as={'span'} color="#fff">
                    Resource Hub!
                  </Text>
                </Heading>
              </Box>
            ) : (
              <Box>
                <video
                  autoPlay
                  loop
                  muted
                  style={{
                    position: 'relative',
                    width: '560px',
                    height: '200px',
                    // zIndex: '-1',
                    objectFit: 'cover',
                    paddingLeft: '5px',
                  }}
                >
                  <source src={Video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <Heading
                  lineHeight={1.1}
                  fontWeight={600}
                  // fontSize="7xl"
                  fontSize={{ base: '5xl', sm: '5xl', lg: '7xl' }}
                  style={{
                    position: 'absolute',
                    top: 143,
                    backgroundColor: '#2c3f52',
                    mixBlendMode: 'darken',
                    color: '#ffffff',
                    height: '205px',
                    width: '100%',
                  }}
                  color="transparent"
                >
                  <Box m={2}>
                    <Text
                      className="textColor"
                      fontFamily={'Georgia'}
                      as={'span'}
                      position={'relative'}

                      // textShadow={'3px 3px 0 #000'}
                    >
                      Developer
                    </Text>
                  </Box>

                  <Text m={2} fontFamily={'Georgia'} as={'span'} color="#fff">
                    Resource Hub!
                  </Text>
                </Heading>
              </Box>
            )}
            <Text
              fontFamily={'Goudy Old Style'}
              fontSize="x-large"
              color={textColor?.backgroundText}
            >
              <strong
                style={{
                  color: textColor?.foregroundText,
                  fontSize: '32px',
                }}
              >
                DevErNote
              </strong>{' '}
              makes it easy to save all of your favorite code snipits and
              articles in one place for ease of access Have a snippet or
              articles you think others will like, share it! Connect with like
              minded people on a platform created for developers by developers
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Button
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
                bg={textColor?.foregroundText}
                _hover={{ bg: 'blue.500' }}
              >
                <Text color={textColor?.backgroundText}>
                  <Link to="/register">Register</Link>
                </Text>
              </Button>
              <Button
                bg={textColor?.backgroundText}
                color={textColor?.foregroundText}
                rounded={'full'}
                size={'lg'}
                fontWeight={'normal'}
                px={6}
              >
                <Link to="/signIn">Sign In</Link>
              </Button>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}
          >
            <Box
              position={'relative'}
              // boxShadow={'2xl'}
              width={'full'}
            >
              <Image
                alt={'Hero Image'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={StandingPic}
              />
            </Box>
          </Flex>
        </Stack>
        <Box mb={10}>
          <Center>
            <Text fontSize="xx-large" color={textColor?.backgroundText} mb={4}>
              Create & Save Snippits
            </Text>
          </Center>
          <Flex
            w={'100%'}
            justifyContent={'center'}
            overflowX="hidden"
            style={{
              backdropFilter: 'blur( 4.5px ) saturate(180%)',
            }}
            bg={'rgba(255, 255,255, 0.05)'}
            boxShadow="large"
            rounded="md"
          >
            <ImageSlider direction={'right'} />
          </Flex>
        </Box>
        <Box pb={10}>
          <Center>
            <Text fontSize="xx-large" color={textColor?.backgroundText} mb={4}>
              View, Edit, & Delete
            </Text>
          </Center>
          <Flex
            w={'100%'}
            justifyContent={'center'}
            overflowX="hidden"
            style={{
              backdropFilter: 'blur( 4.5px ) saturate(180%)',
            }}
            bg={'rgba(255, 255,255, 0.05)'}
            boxShadow="2xl"
            rounded="md"
          >
            <ImageSlider direction={'left'} />
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
