import { useRef, useState } from 'react';
import { Box, Button, Center, useToast, Avatar } from '@chakra-ui/react';
import { useImageStore } from '../../stores/useImageStore';
import { useUserStore } from '../../stores/useUserStore';
import { useUtilsStore } from '../../stores/useUtilsStore';

import imageCompression from 'browser-image-compression';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';

const FileUpload = props => {
  const { saveUrl, addFile, getFilePreview, isLoading } = useImageStore(
    (state: any) => state
  );
  const { userData, getUsers } = useUserStore((state: any) => state);
  const { addLinkFile, getLinkFilePreview } = useUtilsStore(
    (state: any) => state
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState(null);

  const toast = useToast();

  const utilsUpload = async file => {
    const response = await addFile(file);

    const getPhoto = await getLinkFilePreview(response?.$id);

    const uploadPhoto = await addLinkFile({
      siteUrl: props.url,
      imageUrl: getPhoto?.href,
      linkImageId: response?.$id,
      siteName: props.name,
      type: props.type,
    });
    setImage(null);
    return uploadPhoto;
  };
  const textColor = SelectedDefaultTextColor();
  const userProfileUpload = async file => {
    try {
      if (file) {
        const response = await addFile(file);

        const getPhoto = await getFilePreview(response?.$id);

        const uploadPhoto = await saveUrl({
          $id: userData.$id || '',
          data: {
            userImage: getPhoto?.href,
            userImageId: response?.$id,
          },
        });
        getUsers();
        setImage(null);
        toast({
          title: 'Sucess',
          description: 'Image has been uploaded.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        return uploadPhoto;
      }
    } catch (err) {
      console.log(err);
      toast({
        title: 'Something Went Wrong',
        description: err,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleUpload = async () => {
    if (image !== null) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(image, options);

        const file = new File([compressedFile], compressedFile.name, {
          type: compressedFile.type,
        });

        // Upload the image
        if (props.uploadPage === 'utils') {
          utilsUpload(file);
        } else if (props.uploadPage === 'userProfile') {
          userProfileUpload(file);
        }
      } catch (err) {
        console.log(err);
        setImage(null);
      }
      // After uploading, you might want to fetch the image or update state accordingly
      // For example, if your API returns the uploaded image URL, you can do:
      // setImage(response.imageUrl);
    } else {
      alert('No file is uploaded');
    }
  };

  return (
    <Box>
      <Box display="none">
        <input
          type="file"
          ref={inputRef}
          onChange={e => setImage(e.currentTarget?.files?.[0] || '')}
        />
      </Box>
      <Center>
        {image ? (
          <Box>
            <Center>
              <Button
                borderColor={textColor?.foregroundText}
                isLoading={isLoading}
                loadingText="Saving"
                variant="outline"
                mb={2}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Center>
            <Center></Center>
          </Box>
        ) : (
          userData && (
            <Button
              borderColor={textColor?.foregroundText}
              isLoading={isLoading}
              variant="outline"
              mb={2}
              onClick={() => inputRef.current?.click()}
            >
              Select File
            </Button>
          )
        )}
      </Center>
      <Center>
        <Box>
          {image && (
            <Avatar
              src={URL.createObjectURL(image)}
              size="2xl"
              objectFit="fill"
              mb={2}
              style={{
                backgroundPosition: 'center bottom', // Adjust this to change the position
              }}
            />
          )}
          <Center>
            {image && (
              <Button mb={2} variant="outline" onClick={() => setImage(null)}>
                Clear
              </Button>
            )}
          </Center>
        </Box>
      </Center>
    </Box>
  );
};

export default FileUpload;
