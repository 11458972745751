import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Text,
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  Image,
} from '@chakra-ui/react';

import { useUserStore } from '../../stores/useUserStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import google from '../../photos/google.png';

const RegisterComponent = () => {
  const { signUpAndSignInGoogleProvider, signUpUser, getUsers, userData } =
    useUserStore((state: any) => state);

  const [name, setName] = useState('');
  const [userName, setUserName] = useState(null || '');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const textColor = SelectedDefaultTextColor();
  const handleSubmit = async () => {
    if (name.trim().length < 3) {
      setError('Name must be at least 3 characters long');
      return;
    }
    if (userName != null && userName?.trim().length < 3) {
      setError('userName must be at least 3 characters long');
      return;
    }

    if (!isValidEmail(email)) {
      setError('Invalid email address');
      return;
    }

    if (password.length < 5) {
      setError('Password must be at least 5 characters long');
      return;
    }

    try {
      await signUpUser({
        name: name,
        userName: userName,
        email: email,
        password: password,
      });

      navigate('/');
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const isValidEmail = (email: string) => {
    // You can use a regular expression to validate the email format
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  return (
    <Box>
      <Box>
        <FormControl color={textColor?.backgroundText} isRequired>
          <FormLabel fontSize="2rem">Name</FormLabel>
          <Input
            value={name}
            onChange={e => {
              setName(e.target.value);
              setError('');
            }}
            borderColor={textColor?.backgroundText}
            variant="flushed"
            type="text"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl color={textColor?.backgroundText} isRequired>
          <FormLabel fontSize="2rem">User Name</FormLabel>
          <Input
            value={userName}
            onChange={e => {
              setUserName(e.target.value);
              setError('');
            }}
            borderColor={textColor?.backgroundText}
            variant="flushed"
            type="text"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl color={textColor?.backgroundText} id="email" isRequired>
          <FormLabel fontSize="2rem">Email</FormLabel>

          <Input
            color={textColor?.backgroundText}
            value={email}
            onChange={e => {
              setEmail(e.target.value);
              setError('');
            }}
            variant="flushed"
            type="email"
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl color={textColor?.backgroundText} id="password" isRequired>
          <FormLabel fontSize="2rem">Password</FormLabel>
          <Input
            color={textColor?.backgroundText}
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              setError('');
            }}
            variant="flushed"
            type="password"
          />
        </FormControl>
      </Box>
      <Box>
        <Text>{error}</Text>
      </Box>
      <Flex mt={4} flexDirection="column" justify="space-around" align="center">
        <Button
          borderRadius="30px"
          mb={4}
          w="100%"
          color={textColor?.backgroundText}
          bg={textColor?.foregroundText}
          onClick={handleSubmit}
        >
          Submit
        </Button>
        <Button
          bg={'orange'}
          borderRadius="30px"
          mb={4}
          w="100%"
          color="white"
          onClick={() => signUpAndSignInGoogleProvider()}
        >
          <Text mr={2}>Sign In With</Text>
          <Image
            src={google}
            alt="google"
            width={30}
            height={30}
            // className="md:h-auto md:w-full"
          />
        </Button>
        <Link to={'/forgotpasswordemail'}>
          <Text color={textColor?.backgroundText}>Forgot Password</Text>
        </Link>
      </Flex>
    </Box>
  );
};

export default RegisterComponent;
