import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';

import { Route, Routes, useParams } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import SignUp from './pages/SignUp';
import ProviderVerification from './pages/ProviderVerification';
import SignIn from './pages/SignIn';
import Header from './component/Header/Header';

import { SelectedBackgroundColor } from './utils/colorSelection';
import PlayGround from './pages/PlayGround';
import Home from './pages/Home';
import Profile from './pages/Profile';
import { ForgotPassword } from './pages/ForgotPassword';
import ForgotPasswordSendEmail from './pages/ForgotPasswordSendEmail';
import Utils from './pages/Utils';
import UtilsLinkUploads from './pages/UtilsLinkUploads';
import PrivateRoutes from './component/WebLinkPreview/PrivateRoute';
import codeDarkCircule from './photos/bbg.png';
import LandingPageComponent from './component/StyleCompnents/LandingPageComponent';
import Social from './pages/Social';
import { userThemeStore } from './stores/useThemesStore';
import { useUserStore } from './stores/useUserStore';

function App() {
  let { userId, secret } = useParams();
  const { theme } = userThemeStore((state: any) => state);
  const { userData } = useUserStore((state: any) => state);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [backgroundGradient, setBackgroundGradient] = useState('');

  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    // Your conditional logic for hooks
    if (theme?.backgroundImage || userData?.length < 1) {
      setBackgroundImage(codeDarkCircule);
    }

    if (theme?.gradient) {
      setBackgroundGradient(
        `linear(to-r, ${theme?.backgroundGradient[0]}, ${theme?.backgroundGradient[1]}, ${theme?.backgroundGradient[2]})`
      );
    }
    if (theme?.gradient === false && theme.backgroundImage === false) {
      setBackgroundGradient('');
    }

    // Add as many conditions and hook calls as needed
  }, [theme]);

  return (
    <Box
      minH={'100vh'}
      className="App"
      bg={SelectedBackgroundColor()}
      backgroundSize="cover"
      backgroundPosition="center"
      bgGradient={backgroundGradient}
      style={
        theme?.backgroundImage || userData?.length < 1
          ? {
              backgroundImage: `url(${backgroundImage})`,
            }
          : {}
      }
    >
      <Header />
      <LandingPageComponent />
      <Box h={'100%'}>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/utilslinkuploads" element={<UtilsLinkUploads />} />
          </Route>
          <Route path="/" element={<LandingPage />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route
            path="/forgotpasswordemail"
            element={<ForgotPasswordSendEmail />}
          />
          <Route path="/playground" element={<PlayGround />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/utils" element={<Utils />} />
          <Route path="/socialhub" element={<Social />} />

          <Route
            path="/providerverification"
            element={<ProviderVerification />}
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default App;
