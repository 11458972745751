import React from 'react';
import { Flex, Text, Box, Center } from '@chakra-ui/react';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';

const Notes = () => {
  const { codeBlockData } = userCodeBlockStore((state: any) => state);
  const textColor = SelectedDefaultTextColor();
  return (
    <Flex
      direction={'column'}
      w={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      mt={8}
    >
      <Box
        color={textColor?.backgroundText}
        maxW="600px"
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        bg={'rgba(44, 63, 82, 0.75)'}
        p={6}
        rounded="md"
        boxShadow="2xl"
        border={'1px solid rgba(255, 255, 255, 0.125)'}
      >
        <Text w="100%" fontSize="3xl" as="u" align="center">
          Notes
        </Text>
        <Box pb={2}>{codeBlockData?.note}</Box>
      </Box>
    </Flex>
  );
};

export default Notes;
