import React from 'react';
import './LandingPage.css';
import { motion, isValidMotionProp } from 'framer-motion';
import {
  Box,
  Flex,
  Container,
  chakra,
  shouldForwardProp,
} from '@chakra-ui/react';

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: prop => isValidMotionProp(prop) || shouldForwardProp(prop),
});

const LandingPageComponent = () => {
  return (
    <Container position={'fixed'} h="100vh" display="flex">
      <ChakraBox
        initial={{ x: '-100%', y: '-100%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 4,
          ease: 'linear',
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
          repeatType: 'loop',
        }}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="2px"
      ></ChakraBox>

      <ChakraBox
        initial={{ x: '-300%', y: '-100%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 4,
          ease: 'linear',
          repeatType: 'loop',
          delay: 2,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={20}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="1px"
      ></ChakraBox>

      <ChakraBox
        initial={{ x: '-600%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 3,
          ease: 'linear',
          repeatType: 'loop',
          delay: 1,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={40}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="2px"
      ></ChakraBox>
      <ChakraBox
        initial={{ x: '-600%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 4,
          ease: 'linear',
          repeatType: 'loop',
          delay: 3,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={80}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="1px"
      ></ChakraBox>
      <ChakraBox
        initial={{ x: '-600%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 3,
          ease: 'linear',
          repeatType: 'loop',
          delay: 2,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={'100%'}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="2px"
      ></ChakraBox>
      <ChakraBox
        initial={{ x: '-600%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 4,
          ease: 'linear',
          repeatType: 'loop',
          delay: 1,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={'200%'}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="1px"
      ></ChakraBox>
      <ChakraBox
        initial={{ x: '-800%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 3,
          ease: 'linear',
          repeatType: 'loop',
          delay: 3,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={'120%'}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="2px"
      ></ChakraBox>
      <ChakraBox
        initial={{ x: '-800%' }}
        animate={{
          x: '2000%',
          y: '2000%',
        }}
        transition={{
          duration: 3,
          ease: 'linear',
          repeatType: 'loop',
          delay: 3,
          // @ts-ignore no problem in operation, although type error appears.
          repeat: Infinity,
        }}
        mt={'320%'}
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100px"
        height="2px"
      ></ChakraBox>
    </Container>
  );
};

export default LandingPageComponent;
