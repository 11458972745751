import React, { useState } from 'react';
import {
  Container,
  Stack,
  Text,
  Box,
  Image,
  useColorMode,
  Flex,
  Input,
  Button,
} from '@chakra-ui/react';
import FoldersFiles from '../component/Home/FoldersFiles';
import ViewCodeBlock from '../component/Home/ViewCodeBlock';
import Notes from '../component/Home/Notes';

const Home = () => {
  const [folderView, setFolderView] = useState('folder');
  return (
    <Box h="100%">
      <Container maxW={'7x1'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={{ base: 10, md: 18 }}
          direction={{ base: 'column', md: 'row' }}
          alignItems={'flex-start'}
          justifyContent={'center'}
        >
          <Box w={{ base: '100%', md: '50%' }}>
            <FoldersFiles
              folderView={folderView}
              setFolderView={setFolderView}
            />
          </Box>

          <Flex w={{ base: '100%', md: '50%' }}>
            <ViewCodeBlock
              folderView={folderView}
              setFolderView={setFolderView}
            />
          </Flex>
        </Stack>
      </Container>
      <Flex w={'100%'}>
        <Notes />
      </Flex>
    </Box>
  );
};

export default Home;
