import { Box, Text } from '@chakra-ui/react';
import { Console, Hook, Unhook } from 'console-feed';
import { Message } from 'console-feed/lib/definitions/Component';
import { useEffect, useState } from 'react';

interface Props {
  logs?: Message[];
  onLog?: (mgs: Message) => void;
  error?: any;
}

export const ConsoleFeed: React.FC<Props> = props => {
  const { onLog } = props;
  const [logs, setLogs] = useState<Message[]>([]);
  const [syntaxError, setSyntaxError] = useState<string | null>(null);

  useEffect(() => {
    const hooked = Hook(
      window.console,
      log => {
        if (onLog) {
          onLog(log as any);
        } else {
          setLogs((currLogs: any) => [...currLogs, log]);
        }
      },
      false
    );

    return () => {
      Unhook(hooked);
    };
  }, [onLog]);

  useEffect(() => {
    setSyntaxError(props?.error?.message);
  }, [props?.error?.message]);

  useEffect(() => {
    const onMessage = (response: MessageEvent<any>) => {
      const data = response.data;
      if (data && data.source === 'jsRunner') {
        switch (data.type) {
          case 'log':
            console.log(...data.message);
            break;
          case 'error':
            if (Array.isArray(data.message)) {
              console.error(...data.message);
            } else {
              console.error(data.message);
            }
        }
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    // Clear syntaxError whenever logs are updated
    setSyntaxError(null);
  }, [props.logs]);

  const _logs = props.logs || logs;
  return (
    <Box maxH="200px" overflow={'auto'}>
      {syntaxError && (
        <Text letterSpacing={'1px'} fontWeight={'bold'} color={'orange'}>
          Syntax Error: {syntaxError}
        </Text>
      )}
      <Console
        styles={{
          BASE_FONT_SIZE: '14px',
          BASE_FONT_FAMILY: `Monaco, "Andale Mono", "Ubuntu Mono", monospace`,
        }}
        variant="dark"
        logs={_logs}
      />
    </Box>
  );
};

export type { Message };
