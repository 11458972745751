import { INewUser } from '../../../types/types';
import { ID, Query } from 'appwrite';
import { account, appWriteConfig, databases } from '../config';

export const getUserTheme = async (name: string) => {
    try {
        const currentAccount = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.themeCollectionId || '',
            [Query.equal('name', [name])]
        );

        return currentAccount;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const getAllThemes = async () => {
    try {
        const allThemes = await databases.listDocuments(
            appWriteConfig.databasesId || '',
            appWriteConfig.themeCollectionId || ''
        );
        return allThemes;
    } catch (err: any) {
        throw new Error(err);
    }
};
