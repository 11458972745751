import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Box, ChakraProvider } from '@chakra-ui/react';
import theme from '../src/theme';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import App from './App';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ChakraProvider>
);
