import { create } from 'zustand';

import { uploadLinkInfo, getLinkInfoByType, getLinksFileId } from '../config/AppWrite/Apis/utilsApi';
import { IUtils } from '../types/types';


export const useUtilsStore = create(set => ({

    linkData: [],


    addLinkFile: async (data: IUtils) => {

        try {
            set({ isLoading: true });
            const response = await uploadLinkInfo(data);
            set({ isLoading: false, linkData: response });

            return response;
        } catch (err) {
            throw new Error(err);
        }
    },
    getLinks: async (data: string) => {

        try {
            set({ isLoading: true });
            const response = await getLinkInfoByType(data)
            set({ isLoading: false, linkData: response?.documents });

        } catch (err) {
            throw new Error(err)
        }
    },
    getLinkFilePreview: async (id: string) => {


        try {
            const response = await getLinksFileId(id);
            return response;
        } catch (err) {
            throw new Error(err);
        }
    },

}))