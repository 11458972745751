import { ID, Query } from 'appwrite';
import { account, appWriteConfig, databases, storage } from '../config';

export const uploadFile = async (file: File) => {
    try {
        const uploadedFile = await storage.createFile(
            appWriteConfig.userImageStorageId,
            ID.unique(),
            file
        );
        return uploadedFile;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const getFileId = async (data: string) => {
    try {
        const getFile = await storage.getFilePreview(
            appWriteConfig.userImageStorageId,
            data
        );


        return getFile;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const savePhotoUrl = async (photo: {
    $id: string;

    data: any;
}) => {

    try {
        const newUserImage = await databases.updateDocument(
            appWriteConfig.databasesId,
            appWriteConfig.userCollectionId,
            photo.$id,
            photo.data
        );

        return newUserImage;
    } catch (err: any) {
        throw new Error(err);
    }
};

export const getPhoto = async (id: string) => {
    try {
        const photo = await databases.getDocument(
            appWriteConfig.databasesId,
            appWriteConfig.userImageStorageId,
            id
        );

        return photo;
    } catch (err) {
        throw new Error(err);
    }
};

export const updatePhoto = async (id: string, data: any) => {
    try {
        const updatedPhoto = await databases.updateDocument(
            appWriteConfig.databasesId,
            appWriteConfig.userImageStorageId,
            id,
            {
                name: data.name,
                description: data.description,
            }
        );
        return updatedPhoto;
    } catch (err) {
        throw new Error(err);
    }
};
export const deletePhoto = async (id: string) => {
    try {
        const deletedPhoto = await storage.deleteFile(
            appWriteConfig.userImageStorageId,
            id
        );
        return deletedPhoto;
    } catch (err) {
        throw new Error(err);
    }
};
