import React from 'react';
import PlayGroundComponent from '../component/PlayGround/PlaygroundComponent';

const PlayGround = () => {
  return (
    <div>
      <PlayGroundComponent />
    </div>
  );
};

export default PlayGround;
