import React from 'react';
import ForgotPasswordSendEmailComponent from '../component/ForgotPassword/ForgotPasswordSendEmailComponent';
import { Box } from '@chakra-ui/react';

const ForgotPasswordSendEmail = () => {
  return (
    <Box>
      <ForgotPasswordSendEmailComponent />
    </Box>
  );
};

export default ForgotPasswordSendEmail;
