import { Outlet, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '../../stores/useUserStore';

const PrivateRoutes = () => {
  const { userData } = useUserStore((state: any) => state);

  return <Outlet />;
  //  userData?.id === process.env.REACT_APP_APPWRITE_MAIN_ROLE ? (
  //   <Outlet />
  // ) : (
  // );
};

export default PrivateRoutes;
