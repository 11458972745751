'use client';
import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  Image,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  Spinner,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import { userCodeBlockStore } from '../../stores/useCodeBlockStore';
import { useUserStore } from '../../stores/useUserStore';
import { SelectedDefaultTextColor } from '../../utils/colorSelection';
import wfolder from '../../photos/wfolder.png';
import documents from '../../photos/documents.png';
interface CodeBlock {
  $id: any;
  folder: string;
  _id: string;
  category: string;
  title: string;
}
interface FoldersFilesProps {
  folderView: string;
  setFolderView: (string) => void;
}

const FoldersFiles: React.FC<FoldersFilesProps> = ({
  folderView,
  setFolderView,
}) => {
  const {
    getUserCodeBlockFolders,
    codeBlockDataList,

    getCodeBlock,
    getCreatorCodeBlock,
    isCodeBlockLoading,

    getUserCodeBlockByCategies,
  } = userCodeBlockStore((state: any) => state);
  const { userData } = useUserStore((state: any) => state);

  useEffect(() => {
    if (userData?.name) {
      getUserCodeBlockFolders(userData?.email);
    }
  }, []);

  const shortString = (
    str: string | undefined | null
  ): string | undefined | null => {
    return str?.slice(0, 10);
  };

  const textColor = SelectedDefaultTextColor();

  return (
    <Box
      w="100%"
      border={'1px solid rgba(255, 255, 255, 0.125)'}
      color={textColor.backgroundText}
      h="500px"
    >
      <Box
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        bg={'rgba(44, 63, 82, 0.75)'}
        p={2}
      >
        <Tabs position="relative" variant="unstyled">
          <TabList>
            <Tab
              onClick={async () => {
                if (userData?.name) {
                  await getUserCodeBlockFolders(userData?.email);
                }
                setFolderView('folder');
              }}
            >
              All Folders
            </Tab>
            <Tab
              onClick={async () => {
                if (userData?.name) {
                  await getCreatorCodeBlock(userData?.email);
                }
                setFolderView('file');
              }}
            >
              All Files
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg={textColor.foregroundText}
            borderRadius="1px"
          />
        </Tabs>
      </Box>
      <Box
        boxShadow="2xl"
        rounded="md"
        h="442px"
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        bg={'rgba(44, 63, 82, 0.75)'}
      >
        <Flex
          overflowY="auto"
          flexWrap="wrap"
          maxWidth="100%"
          flexDirection="row"
          justifyContent="flex-start"
        >
          {codeBlockDataList?.map((item: CodeBlock) => (
            <Flex
              key={item?.$id}
              onClick={async () => {
                if (folderView === 'folder') {
                  setFolderView('singleFiles');
                  if (userData?.name) {
                    await getUserCodeBlockByCategies(userData?.email, {
                      category: item?.folder,
                    });
                  }
                } else {
                  await getCodeBlock(
                    item?.$id,
                    item?.category,
                    userData?.email
                  );
                }
              }}
              maxWidth="100%"
              mt={5}
              color="white"
              flexWrap="wrap"
              flexDirection="column"
              alignItems="center"
            >
              {userData?.name && isCodeBlockLoading ? (
                <Spinner mt={4} mr={20} size="xl" />
              ) : (
                <Flex direction={'column'} alignItems="center" cursor="grab">
                  <Image
                    src={folderView === 'folder' ? wfolder : documents}
                    alt="Image"
                    width={100}
                    height={100}
                  />
                  <Box>
                    {folderView === 'folder' && item.folder?.length > 0 ? (
                      <Tooltip label={item.folder}>
                        {shortString(item.folder)}
                      </Tooltip>
                    ) : item?.folder === '' ? (
                      '*No Folder'
                    ) : (
                      <Tooltip label={item.title}>
                        {shortString(item.title)}
                      </Tooltip>
                    )}
                  </Box>
                </Flex>
              )}
              {/* </Skeleton> */}
            </Flex>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default FoldersFiles;
