'use client';
import React, { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import CodeMirror from '@uiw/react-codemirror';
import { Text, Flex, Button, Box, Image } from '@chakra-ui/react';
import vdoublearrow from '../../photos/vdoublearrow.svg';
import hdoublearrow from '../../photos/hdoublearrow.svg';
import {
  SelectedDefaultTextColor,
  editorTheme,
} from '../../utils/colorSelection';
import { javascript } from '@codemirror/lang-javascript';
// import { aura } from '@uiw/codemirror-theme-aura';
// import { nord } from '@uiw/codemirror-theme-nord';

import './editorstyles.css';

const JSEditor = (props: { setJsValue: any; value: string }) => {
  const { setJsValue, value } = props;

  let location = useLocation();

  const [open, setOpen] = useState(true);
  const [openVerticle, setOpenVerticle] = useState(true);
  const onChange = useCallback(
    (val: any, viewUpdate: any) => {
      setJsValue(val);
      if (typeof window !== 'undefined') {
        localStorage.setItem(
          'DevErNote-current-notejavascript',
          JSON.stringify(val)
        );
      }
    },
    [setJsValue]
  );
  const textColor = SelectedDefaultTextColor();
  const jsValue = () => {
    if (typeof window !== 'undefined') {
      const jsonValue = localStorage.getItem(
        'DevErNote-current-notejavascript'
      );

      return jsonValue != null ? JSON.parse(jsonValue) : '';
    } else return '';
  };
  return (
    <Flex
      flexDirection="column"
      width={{ md: open ? '100%' : '20%', base: open ? '100%' : '100%' }}
    >
      <Flex
        pl={3}
        pr={{ base: 10, md: 0 }}
        w="100%"
        justifyContent="space-between"
      >
        <Flex>
          <Text fontSize="1.8rem" color={textColor?.backgroundText}>
            JS
          </Text>
          <Text ml={2} fontSize="md"></Text>
        </Flex>
        {location.pathname !== '/home' &&
          location.pathname !== '/socialhub' && (
            <Button
              display={{ base: 'none', md: 'block' }}
              _hover={{ bg: textColor?.foregroundText }}
              variant={'ghost'}
              onClick={() => setOpen(prevOpen => !prevOpen)}
            >
              <Image alt="arrow" src={hdoublearrow} width={30} height={30} />
            </Button>
          )}
        {location.pathname !== '/home' && (
          <Button
            display={{ base: 'block', md: 'none' }}
            _hover={{ bg: textColor?.foregroundText }}
            variant={'ghost'}
            onClick={() => setOpenVerticle(prevOpen => !prevOpen)}
          >
            <Image alt="arrow" src={vdoublearrow} width={30} height={30} />
          </Button>
        )}
      </Flex>
      <Box
        rounded="md"
        display={!openVerticle ? 'none' : 'block'}
        border={'1px solid rgba(255, 255, 255, 0.125)'}
        borderRadius={10}
        style={{
          backdropFilter: 'blur( 4.5px ) saturate(180%)',
        }}
        bg={'rgba(44, 63, 82, 0.75)'}
        px={2}
        boxShadow="2xl"
      >
        <CodeMirror
          className="cm-editor"
          value={location.pathname === '/playground' ? jsValue() : value}
          style={{
            borderRadius: '20px',
          }}
          height="300px"
          extensions={[javascript({ jsx: true })]}
          onChange={onChange}
          theme={editorTheme}
        />
      </Box>
    </Flex>
  );
};

export default JSEditor;
