import { Client, Account, Databases, Storage, Avatars } from "appwrite";

export const appWriteConfig = {
    projectId: process.env.REACT_APP_APPWRITE_PROJECT_ID || '',
    url: process.env.REACT_APP_APPWRITE_URL || '',
    databasesId: process.env.REACT_APP_APPWRITE_DATABASE_ID,
    userImageStorageId: process.env.REACT_APP_APPWRITE_USER_IMAGE_STORAGE_ID,
    userCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_USERS_ID,
    themeCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_THEMES_ID,
    utilsCssCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_UTILS_CSS_ID,
    codeBlockCollectionId: process.env.REACT_APP_APPWRITE_COLLECTION_CODEBLOCK_ID,



}

export const client = new Client()
client.setProject(appWriteConfig.projectId)
client.setEndpoint(appWriteConfig.url)
export const account = new Account(client)
export const storage = new Storage(client)
export const databases = new Databases(client)