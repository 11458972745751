'use client';
import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Button,
  Container,
  Textarea,
  Box,
  Input,
  TabIndicator,
  InputGroup,
  Center,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import { SelectedDefaultTextColor } from '../../utils/colorSelection';

import JSEditor from '../editors/JSEditor';
import HtmlEditor from '../editors/HtmlEditor';
import CssEditor from '../editors/CssEditor';
import { ConsoleFeed, Message } from './ConsoleFeed';
import { JsRunnerEnv, runJS } from './RunJs';

import SaveCodeModal from './SaveCodeModel';

interface Props {
  runner?: 'iframe' | 'webWorker';
}

const PlayGroundComponent: React.FC<Props> = props => {
  const { runner = 'webWorker' } = props;
  const [logs, setLogs] = useState([]);
  const [srcDoc, setSrcDoc] = useState('');
  const [code, setCode] = useState('');
  const [isRunConfirmed, setIsRunConfirmed] = useState(false);
  const [jsValue, setJsValue] = useState();
  const [htmlValue, setHtmlValue] = useState();
  const [cssValue, setCssValue] = useState();
  const [title, setTitle] = useState('');
  const [note, setNote] = useState('');
  const jsRunnerEnvRef = useRef<JsRunnerEnv>();
  const [syntaxError, setSyntaxError] = useState();

  const runCode = async () => {
    const doRun = isRunConfirmed || window.confirm('Do you want to run code?');
    if (doRun) {
      setLogs([]);
      setIsRunConfirmed(true);
      runJS(code, runner, jsRunnerEnvRef as unknown as JsRunnerEnv);
    }
  };

  const onLog = useCallback((log: Message) => {
    setLogs(pre => [...pre, log]);
  }, []);
  const textColor = SelectedDefaultTextColor();

  useEffect(() => {
    setCode(jsValue);

    try {
      eval(jsValue);
    } catch (error) {
      setSyntaxError(error);
    }

    const timeout = setTimeout(() => {
      setSrcDoc(`
            <html>
            <body>${htmlValue}</body>
            <style>${cssValue}</style>
            <script>${jsValue}</script
            </html>
            `);
    }, 250);
    return () => clearTimeout(timeout);
  }, [htmlValue, cssValue, jsValue]);

  useEffect(() => {
    const storedJsValue = localStorage.getItem(
      'DevErNote-current-notejavascript'
    );
    const storedCss = localStorage.getItem('DevErNote-current-notecss');
    const storedTitle = localStorage.getItem('DevErNote-current-notetitle');
    const storedNote = localStorage.getItem('DevErNote-current-note');
    const storedHtml = localStorage.getItem('DevErNote-current-notehtml');
    if (storedTitle) {
      setTitle(JSON.parse(storedTitle));
    }
    if (storedNote) {
      setNote(JSON.parse(storedNote));
    }
    if (storedCss) {
      setCssValue(JSON.parse(storedCss));
    }
    if (storedHtml) {
      setHtmlValue(JSON.parse(storedHtml));
    }
    if (storedJsValue) {
      setJsValue(JSON.parse(storedJsValue));
    }
  }, []);

  return (
    <Container maxW="90%">
      <Flex justifyContent={'center'}>
        {/* <Text
          my={4}
          fontWeight={400}
          fontSize="4xl"
          color={textColor?.backgroundText}
        >
          Playground
        </Text> */}
      </Flex>
      <Flex>
        <InputGroup mt={4}>
          {/* <InputLeftAddon children="Title" /> */}
          <Input
            color={textColor?.backgroundText}
            value={title}
            onChange={e => {
              setTitle(e.target.value);
              localStorage.setItem(
                'DevErNote-current-notetitle',
                JSON.stringify(title)
              );
            }}
            variant="flushed"
            placeholder="Title"
            _placeholder={{ fontSize: '24px' }}
            w="200px"
          />
        </InputGroup>
      </Flex>

      <Flex
        direction={{ base: 'column', md: 'row' }}
        mx={3}
        mt={4}
        minWidth="max-content"
        alignItems="center"
        gap="6"
        h="100%"
      >
        <JSEditor value={jsValue} setJsValue={setJsValue} />

        <HtmlEditor value={htmlValue} setHtmlValue={setHtmlValue} />
        <CssEditor value={cssValue} setCssValue={setCssValue} />
      </Flex>
      <Center></Center>
      <Flex
        mt={4}
        justifyContent="space-between"
        direction={{ base: 'column', md: 'row' }}
      >
        <Tabs
          boxShadow="2xl"
          position="relative"
          variant="unstyled"
          isFitted
          mt={2}
          w={{ base: '100%', md: '49%' }}
          border={'1px solid rgba(255, 255, 255, 0.125)'}
          borderRadius={10}
          style={{
            backdropFilter: 'blur( 4.5px ) saturate(180%)',
          }}
          bg={'rgba(44, 63, 82, 0.75)'}
          mb={{ base: 4, md: 0 }}
          px={2}
        >
          <TabList>
            <Tab>
              <Text p={2} fontSize="1.4rem" color={textColor?.backgroundText}>
                Console
              </Text>
            </Tab>
            <Tab>
              <Text p={2} fontSize="1.4rem" color={textColor?.backgroundText}>
                Browser
              </Text>
            </Tab>
          </TabList>
          <TabIndicator
            w="10%"
            mt="-1.5px"
            height="2px"
            bg={textColor?.foregroundText}
          />

          <TabPanels>
            <TabPanel maxH="500px" w="100%">
              <Flex overflow={'auto'}>
                <ConsoleFeed logs={logs} onLog={onLog} error={syntaxError} />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex flexDirection="column">
                <Flex flexDirection="column">
                  <Flex>
                    <iframe
                      srcDoc={srcDoc}
                      style={{ border: '0px' }}
                      title="output"
                      sandbox="allow-scripts"
                      width="100%"
                      height="200px"
                    />
                  </Flex>
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Box
          boxShadow="2xl"
          w={{ base: '100%', md: '49%' }}
          mt={2}
          border={'1px solid rgba(255, 255, 255, 0.125)'}
          borderRadius={10}
          style={{
            backdropFilter: 'blur( 4.5px ) saturate(180%)',
          }}
          bg={'rgba(44, 63, 82, 0.75)'}
          px={2}
        >
          <Text
            fontSize="1.4rem"
            color={textColor?.backgroundText}
            align="center"
            mt={4}
          >
            Notes:
          </Text>
          <Box p={4} mt={4}>
            <Textarea
              border={'1px solid rgba(255, 255, 255, 0.125)'}
              p={'2'}
              value={note}
              onChange={e => {
                setNote(e.target.value);
                localStorage.setItem(
                  'DevErNote-current-note',
                  JSON.stringify(note)
                );
              }}
              focusBorderColor={textColor?.foregroundText}
              h="200px"
              color={textColor?.backgroundText}
            ></Textarea>
          </Box>
        </Box>
      </Flex>
      <Center>
        <Flex
          maxW="380px"
          m={4}
          border={'1px solid rgba(255, 255, 255, 0.125)'}
          borderRadius={10}
          style={{
            backdropFilter: 'blur( 4.5px ) saturate(180%)',
          }}
          bg={'rgba(44, 63, 82, 0.75)'}
          p={2}
        >
          <Button
            // w="150px"

            ml={4}
            size={'md'}
            fontWeight={'normal'}
            px={6}
            variant="ghost"
            _hover={{ borderColor: textColor?.foregroundText }}
            onClick={runCode}
            color={textColor?.backgroundText}
            // border={'1px solid rgba(255, 255, 255, 0.125)'}
          >
            {' '}
            &#10148; Run
          </Button>
          <Button
            // w="150px"

            ml={4}
            size={'md'}
            fontWeight={'normal'}
            px={6}
            variant="ghost"
            _hover={{ borderColor: textColor?.foregroundText }}
            onClick={() => setLogs([])}
            color={textColor?.backgroundText}
          >
            {' '}
            &#10148; Clear
          </Button>
          <SaveCodeModal
            title={title}
            setTitle={setTitle}
            html={htmlValue}
            css={cssValue}
            javascript={jsValue}
            note={note}
          />
        </Flex>
      </Center>
    </Container>
  );
};

export default PlayGroundComponent;
